import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    IconButton,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
    DrawerBody,
    Flex,
    Spacer,
    Link,
    Button,
    useDisclosure
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useAuthState } from './AuthContext/authContext';

export default function MobileDrawer({headerdata}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef();
    const navigate=useNavigate();
    const {logOut} = useAuthState();

    return (
        <>  
        <IconButton m={5} display={{base:"flex", md:"none"}} icon={<HamburgerIcon/>} ref={btnRef} onClick={onOpen} />
            <Drawer placement={"left"} onClose={onClose} isOpen={isOpen} finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>Menu</DrawerHeader>
                    <DrawerBody>
                        <Flex direction="column" height="100%" gap='2'>
                            {headerdata.map((item, i) => (
                                <Link as={RouterLink} to={item.dest} onClick={onClose} key={i}>
                                    <Button variant="nav"> {item.label} </Button>
                                </Link>
                            ))}
                            <Spacer />
                            <Button mb={3} colorScheme='red' onClick={() => { logOut(); navigate(0) }}>Log Out</Button>
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}