import { useState, useMemo } from 'react';
import {useTable, useAsyncDebounce, useGlobalFilter, useSortBy, usePagination} from 'react-table';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Input,
    InputGroup,
    InputLeftAddon,
    Flex,
    TableCaption,
    Button,
    Select,
    Text,
    NumberInput,
    NumberInputField,
  } from '@chakra-ui/react';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
  
    return (
      <Flex w="100%">
      <InputGroup>
        <InputLeftAddon>Search:{' '}</InputLeftAddon>
        <Input
          minW={200}
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
        </InputGroup>
      </Flex>
    )
  }

export function CTable(tabledata) {
    const columns = useMemo(() => tabledata.columns, [tabledata]); 
    const data = useMemo(() => tabledata.data, [tabledata]);
    if(tabledata.initialState && (!tabledata.initialState.pageSize || !tabledata.initialState.pageIndex)) {
      tabledata.initialState.pageSize = 10;
      tabledata.initialState.pageIndex = 0;
    }
    const initialState = useMemo(() => tabledata.initialState || {
      pageSize:10,
      pageIndex:0
    }, [tabledata]);
    console.log(columns);
    console.log(data);
    console.log(initialState);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }    
    } = useTable({columns, data, initialState:initialState},useGlobalFilter,useSortBy, usePagination);

    return(
        <TableContainer>
        <Table whiteSpace="normal" {...getTableProps()}>
        <TableCaption>
        <Flex direction="row" justifyContent="end" alignContent="center" gap={5}>
        <Flex direction="row" align="center">
        <Button m={1} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>
        <Button m={1} onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </Button>
        <Text m={1}>
          Page {" "}
          <Text as="b">
            {pageIndex + 1} of {pageOptions.length}
          </Text>
        </Text>
        <Button m={1}  onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </Button>
        <Button m={1} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </Button>
        </Flex>
        <Flex direction="row" gap={1} align="center">
        <Text m={1}>
          Go:
        </Text>
        <NumberInput
        m={1}
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            maxW="100px"
          ><NumberInputField/></NumberInput>
          <Text m={1}>
            Show:
          </Text>
        <Select m={1}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          maxW="150px"
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
        </Flex>
        </Flex>
        </TableCaption>
        <Thead>
          <Tr>
            <Th colSpan={4}>
            <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          </Th>
          </Tr>
   {// Loop over the header rows
   headerGroups.map(headerGroup => (
     // Apply the header row props
     <Tr {...headerGroup.getHeaderGroupProps()}>
       {// Loop over the headers in each row
       headerGroup.headers.map(column => (
         // Apply the header cell props
         <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
           {// Render the header
           column.render('Header')}
                             <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
         </Th>
       ))}
     </Tr>
   ))}
 </Thead>
 <Tbody {...getTableBodyProps()}>
   {// Loop over the table rows
   page.map((row,i) => {
     // Prepare the row for display
     prepareRow(row)
     return (
       // Apply the row props
       <Tr {...row.getRowProps()}>
         {// Loop over the rows cells
         row.cells.map(cell => {
           // Apply the cell props
           return (
             <Td maxW="200px" {...cell.getCellProps()}>
               {// Render the cell contents
               cell.render('Cell')}
             </Td>
           )
         })}
       </Tr>
     )
   })}
 </Tbody>
</Table>
</TableContainer>
    )
}

export function NSTable(tabledata) {
  const columns = useMemo(() => tabledata.columns, [tabledata]); 
  const data = useMemo(() => tabledata.data, [tabledata]);
  const initialState = useMemo(() => tabledata.initialState, [tabledata]);
  console.log(columns);
  console.log(data);
  console.log(initialState);
  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      state,
      prepareRow,
  } = useTable({columns, data, initialState:initialState},useSortBy);

  return(
      <TableContainer>
      <Table whiteSpace="normal" {...getTableProps()}>
      <Thead>
 {// Loop over the header rows
 headerGroups.map(headerGroup => (
   // Apply the header row props
   <Tr {...headerGroup.getHeaderGroupProps()}>
     {// Loop over the headers in each row
     headerGroup.headers.map(column => (
       // Apply the header cell props
       <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
         {// Render the header
         column.render('Header')}
                           <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
       </Th>
     ))}
   </Tr>
 ))}
</Thead>
<Tbody {...getTableBodyProps()}>
 {// Loop over the table rows
 rows.map(row => {
   // Prepare the row for display
   prepareRow(row)
   return (
     // Apply the row props
     <Tr {...row.getRowProps()}>
       {// Loop over the rows cells
       row.cells.map(cell => {
         // Apply the cell props
         return (
           <Td maxW="200px" {...cell.getCellProps()}>
             {// Render the cell contents
             cell.render('Cell')}
           </Td>
         )
       })}
     </Tr>
   )
 })}
</Tbody>
</Table>
</TableContainer>
  )
}