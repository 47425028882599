import React, { useEffect, useState, useMemo } from "react";
import dayjs from 'dayjs';
import {
  Center,
  VStack,
  Spacer,
  useDisclosure,
  Flex,
  Heading,
  Text,
  Box,
  CircularProgress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  NumberInput,
  NumberInputField,
  useToast,
  Card,
  CardBody,
  Select,
  useBoolean,
} from '@chakra-ui/react';
import { CTable as Table } from './components/table';
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import Loading from "./components/loading";
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import GetCardDetails from './getcardetails';
import { useForm } from "react-hook-form";
const host = import.meta.env.VITE_REACT_APP_HOST;
const stripePromise = loadStripe(import.meta.env.VITE_REACT_APP_STRIPE_PUBLIC_KEY);

export default function Returns() {
  const [saleslist, setSaleslist] = useState([]);
  const [saleID, setSaleID] = useState();
  const [saleloading, setSaleLoading] = useState(false);
  const [saledetails, setSaleDetails] = useState([]);
  const [returnSKUs, setReturnSKUs] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [submitting, setSubmitting] = useState(false);
  const [dataloading, setLoading] = useState(true);
  const { data: salelist, isLoading, mutate } = useSWR(host + "/api/getsalelist");
  const [clientSecret, setClientSecret] = useState({});
  const { isOpen: SpinnerOpen, onOpen: OpenSpinner, onClose: CloseSpinner } = useDisclosure();
  const [stripeloading, setStripeLoading] = useState();
  const [PMShow, setPMShow] = useBoolean();
  const navigate = useNavigate();
  const steps = [
    { title: 'First', description: 'Sale Details' },
    { title: 'Second', description: 'Damage' },
    { title: 'Third', description: 'Return' },
    { title: 'Review', description: 'Confirmation' },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  })
  useEffect(() => {
    if (!isLoading) {
      console.log(salelist);
      salelist.forEach((item, index, arr) => {
        arr[index].InvoiceDate = dayjs(item.InvoiceDate);
      });
      const newsalelist = salelist.filter(item => item.OrderStatus === "FULFILLED" || item.OrderStatus === "AUTHORISED");
      setSaleslist(newsalelist);
      setLoading(false);
    }
  }, [salelist]);

  const stripeoptions = useMemo(() => { return {
    clientSecret:clientSecret?.paymentIntents?.client_secret,
  }},[clientSecret]);

  const { register, handleSubmit } = useForm()

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Order Date',
          accessor: 'OrderDate',
          sortDescFirst: 'true',
          Cell: ({ cell }) => {
            return dayjs(cell.value).format("MM/DD/YYYY");
          },
        },
        {
          Header: 'Customer',
          accessor: 'Customer',
          style: { 'white-space': 'unset' }
        },
        {
          Header: 'Invoice Number',
          accessor: 'InvoiceNumber'
        },
        {
          Header: 'Order Status',
          accessor: 'OrderStatus',
          Cell: ({ cell }) => {
            if (cell.row.original.OrderStatus === "FULFILLED" && cell.row.original.CombinedPaymentStatus === "PAID") {
              return "PAID"
            } else {
              return cell.row.original.OrderStatus
            }
          }
        },
        {
          Header: 'Action',
          accessor: 'refund',
          Cell: ({ cell }) => {
            if (cell.row.original.OrderStatus === "FULFILLED") {
              if (cell.row.original.CombinedPaymentStatus === "PAID") {
                return (
                  <Button m={{ base: 1, lg: 2 }} onClick={() => {
                    onOpen(); setReturnSKUs([]); setActiveStep(1); setSaleLoading(true); setSaleID({ 'SaleID': cell.row.original.SaleID, 'InvoiceNumber': cell.row.original.InvoiceNumber, 'Customer': cell.row.original.Customer }); fetch(host + "/api/getsaledetails", {
                      method: "POST",
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(cell.row.original)
                    }).then(res => res.json()).then(results => {
                      setSaleDetails(results);
                      setSaleLoading(false);
                    })
                  }}>Start Return</Button>
                )
              } else {
                return (
                  <>
                    {!!cell.row.original.CustomerData?.stripeID && <Button colorScheme="green" m={{ base: 1, lg: 2 }} isLoading={stripeloading === cell.row.original.SaleID} onClick={() => {
                      setStripeLoading(cell.row.original.SaleID); fetch(host + "/api/stripepaymentmethods", {
                        method: "POST",
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(cell.row.original)
                      }).then(res => res.json()).then(results => {
                        OpenSpinner()
                        setClientSecret(results);
                        setStripeLoading();
                      })
                    }}>{!!cell.row.original.CustomerData.cardToken ? "Process Payment" : "One Time Payment"}</Button>}
                    <Button m={{ base: 1, lg: 2 }} onClick={() => {
                      onOpen(); setReturnSKUs([]); setActiveStep(1); setSaleLoading(true); setSaleID({ 'SaleID': cell.row.original.SaleID, 'InvoiceNumber': cell.row.original.InvoiceNumber, 'Customer': cell.row.original.Customer }); fetch(host + "/api/getsaledetails", {
                        method: "POST",
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(cell.row.original)
                      }).then(res => res.json()).then(results => {
                        setSaleDetails(results);
                        setSaleLoading(false);
                      })
                    }}>Start Return</Button>
                  </>
                )
              }
            } else {
              return (
                <>
                  <Button m={{ base: 1, lg: 2 }} onClick={() => {
                    setSaleLoading(true); fetch(host + "/api/getorderdetails", {
                      method: "POST",
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(cell.row.original)
                    }).then(res => res.json()).then(results => {
                      navigate("/neworder", { state: results });
                      setSaleLoading(false);
                    })
                  }}>Edit Order</Button>
                  <Button m={{ base: 1, lg: 2 }} colorScheme="red" onClick={() => {
                    setSaleLoading(true);; fetch(host + "/api/deleteorder", {
                      method: "POST",
                      credentials: "include",
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(cell.row.original)
                    }).then(res => {
                      setSaleLoading(false);
                      mutate();
                      if (res.ok) {
                        toast({
                          title: 'Success!',
                          description: 'Successfully deleted order',
                          status: "success",
                          duration: 9000,
                          isClosable: true,
                        })
                      } else if (res.status === "403") {
                        toast({
                          title: 'Unauthorized Action',
                          description: "You don't have access to this action",
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        })
                      } else {
                        toast({
                          title: 'Error!',
                          description: 'Server Error',
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        })
                      }
                    })
                  }}>Delete Order</Button>
                </>
              )
            }
          }
        }
      ]
    , []
  );
  const initialState = useMemo(() => {
    sortBy: [
      {
        id: 'InvoiceDate',
        desc: 'false'
      }
    ]
  },[]);
  const data = useMemo(() => saleslist, [saleslist]);

  const step2data = useMemo(() => {
    return returnSKUs.filter(item => item.return !== (undefined || null || 0))
  }, [returnSKUs])

  console.log(returnSKUs);
  const step3data = useMemo(() => returnSKUs, [returnSKUs]);
  const toast = useToast();
  const onSubmit = (values) => {
    const od = clientSecret;
    od.PaymentMethod = values.PaymentMethod;
    fetch(host + "/api/invoicepayment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials:"include",
      data: JSON.stringify(od)
    }).then(async (res) => {
      if (res.ok) {
        toast({
          title: 'Success!',
          description: 'Payment Processed',
          status: "success",
          duration: 9000,
          isClosable: true,
        })
        onClose();
      } else {
        const { message } = await res.json();
        toast({
          title: 'Payment Failed',
          description: message,
          status: 'error',
          duration: 4500,
          isClosable: true,
        })
      }
    })
}
  return (
    <>
      {(dataloading || saleloading) ? <Loading /> : (
        <>
          <Modal isOpen={SpinnerOpen} onClose={CloseSpinner}>
            <ModalOverlay />
            <ModalContent padding={10}>
              <ModalHeader>Process Payment</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex direction="column">
                  <Card mb={4}>
                    <CardBody>
                      <Text>Customer: {clientSecret.Invoice?.Customer}</Text>
                      <Text>Sales Rep: {clientSecret.Invoice?.CustomerData.SalesRepresentative}</Text>
                      <Text>Invoice Number: {clientSecret.Invoice?.InvoiceNumber}</Text>
                      <Text>Amount to Charge: ${clientSecret.amount}</Text>
                    </CardBody>
                  </Card>
                  {!!clientSecret.paymentmethods?.length &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Select {...register("PaymentMethod")} placeholder="Select Payment Method">
                        {clientSecret.paymentmethods?.map(pm => {
                          return <option value={pm.id}>......{pm.card.last4}</option>
                        })}
                      </Select>
                      <Button type="submit">Process</Button>
                    </form>}
                    {(PMShow || !clientSecret.paymentmethods?.length) &&
                    <Elements stripe={stripePromise} options={stripeoptions}>
                    <GetCardDetails clientSecret = {clientSecret.paymentIntents?.client_secret} data = {clientSecret} customer={clientSecret.customer} onClose={CloseSpinner} host={host} />
                  </Elements>
                  }
                  {!!clientSecret.paymentmethods?.length && <Button onClick={setPMShow.toggle}>{(PMShow || !clientSecret.paymentmethods?.length) ? "Remove New Card Form" : "Use One Time Card"}</Button>}
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Box>
            <Heading>Sales Orders</Heading>
            <Text>This module shows current orders. You can edit and delete an order or create a return/refund for invoiced orders.</Text>
          </Box>
          <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
            <ModalOverlay />
            <ModalContent maxH="1000px" maxW="1000px">
              <ModalHeader>Returns and Refunds Detail</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {(saleloading) ? (
                  <Flex grow={1} align="center" justify="center">
                    <CircularProgress isIndeterminate color='green.300' />
                  </Flex>
                ) : (
                  <Box>
                    <Stepper index={activeStep}>
                      {steps.map((step, index) => (
                        <Step key={index}>
                          <StepIndicator>
                            <StepStatus
                              complete={<StepIcon />}
                              incomplete={<StepNumber />}
                              active={<StepNumber />}
                            />
                          </StepIndicator>
                          <Box flexShrink='0'>
                            <StepTitle>{step.title}</StepTitle>
                            <StepDescription>{step.description}</StepDescription>
                          </Box>
                          <StepSeparator />
                        </Step>
                      ))}
                    </Stepper>
                    {(() => {
                      switch (activeStep) {
                        case 1:
                          const step0columns = [
                            {
                              Header: 'SKU',
                              accessor: 'SKU'
                            },
                            {
                              Header: 'Name',
                              accessor: 'Name'
                            },
                            {
                              Header: 'Quantity',
                              accessor: 'Quantity'
                            },
                            {
                              Header: 'Price',
                              accessor: 'Price'
                            },
                            {
                              Header: 'Returned',
                              accessor: 'return',
                              Cell: ({ cell }) => {
                                return (
                                  <NumberInput name={cell.row.original.SKU} value={returnSKUs.filter(item => item.SKU === cell.row.original.SKU).length ? returnSKUs.filter(item => item.SKU === cell.row.original.SKU).shift().return : ""} onChange={e => {
                                    returnSKUs.filter(item => item.SKU === cell.row.original.SKU).length ? setReturnSKUs(returnSKUs.map(item => {
                                      if (item.SKU === cell.row.original.SKU) {
                                        item.return = e;
                                        item.price = cell.row.original.Price;
                                        return item
                                      } else {
                                        return item
                                      }
                                    })) : setReturnSKUs([...returnSKUs, { SKU: cell.row.original.SKU, return: e, price: cell.row.original.Price }])
                                  }}><NumberInputField /></NumberInput>
                                )
                              }
                            }
                          ];
                          const step0data = saledetails.Lines;
                          return (
                            <Box>
                              <Text fontSize="xl" as="b"> Customer Name:</Text><Text fontSize="xl"> {saledetails.Customer} </Text>
                              <Text fontSize="xl" as="b"> Invoice Number:</Text><Text fontSize="xl"> {saledetails.InvoiceNumber} </Text>
                              <Table data={step0data} columns={step0columns} />
                            </Box>
                          );
                        case 2:
                          return (
                            <Box>
                              <Center>
                                <VStack>
                                  <Spacer />
                                  <Text fontSize="2xl">Are we restocking any of the items?</Text>
                                </VStack>
                              </Center>
                            </Box>
                          );
                        case 3:
                          const step2columns = [
                            {
                              Header: 'SKU',
                              accessor: 'SKU'
                            },
                            {
                              Header: 'Returned',
                              accessor: 'return'
                            },
                            {
                              Header: 'Restock',
                              accessor: 'restock',
                              Cell: ({ cell }) => {
                                return (
                                  <NumberInput max={cell.row.original.return} min="0" value={returnSKUs.filter(item => item.SKU === cell.row.original.SKU).length ? returnSKUs.filter(item => item.SKU === cell.row.original.SKU).shift().restock : ""} onChange={e => setReturnSKUs(a => a.map(item => {
                                    if (item.SKU === cell.row.original.SKU) {
                                      item.restock = e;
                                      return item
                                    } else {
                                      if(!item.restock){
                                        item.restock = 0;
                                      }
                                      return item
                                    }
                                  }))}><NumberInputField /></NumberInput>
                                )
                              }
                            }
                          ]
                          return (
                            <Box>
                              <Center>
                                <VStack>
                                  <Heading>Restock</Heading>
                                  <Text>Enter the non-damaged quantity</Text>
                                  <Table data={step2data} columns={step2columns} />
                                </VStack>
                              </Center>
                            </Box>
                          );
                        case 4:

                          const step3columns = [
                            {
                              Header: 'SKU',
                              accessor: 'SKU'
                            },
                            {
                              Header: 'Returned',
                              accessor: 'return'
                            },
                            {
                              Header: 'Restock',
                              accessor: 'restock',
                            }
                          ];
                          return (
                            <Box>
                              <Text fontSize="xl" as="b"> Customer Name:</Text><Text fontSize="xl"> {saledetails.Customer} </Text>
                              <Text fontSize="xl" as="b"> Invoice Number:</Text><Text fontSize="xl"> {saledetails.InvoiceNumber} </Text>
                              <Table data={step3data} columns={step3columns} />
                            </Box>
                          )
                        default:
                          return (
                            <Box>
                              <Text>Error with ActiveStep</Text>
                            </Box>
                          )
                      }
                    })(activeStep)}
                  </Box>
                )}
              </ModalBody>

              <ModalFooter>
                {activeStep > 1 &&
                  <Button mr={3} onClick={() => setActiveStep(a => a - 1)}>
                    Back
                  </Button>
                }
                {activeStep === 2 &&
                  <Button mr={3} colorScheme='green' onClick={() => { setActiveStep(3) }}>Yes</Button>
                }
                {activeStep === 4 ? (
                  <Button colorScheme='green' mr={3} isLoading={submitting} onClick={() => {
                    setSubmitting(true);
                    const postbody = {
                      SaleData: saleID,
                      returnSKUs: returnSKUs
                    };
                    fetch(host + "/api/postreturn", {
                      method: "POST",
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      credentials:"include",
                      body: JSON.stringify(postbody)
                    }).then(res => {
                      if (res.status === 200) {
                        setTimeout(() => {
                          setSubmitting(false);
                        }, 400);
                        toast({
                          title: 'Success!',
                          description: 'Credit Note Created!',
                          status: "success",
                          duration: 9000,
                          isClosable: true,
                        })
                        onClose();
                      } else if (res.status === 403) {
                        navigate("/");
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Credit Note not created!',
                          status: 'error',
                          duration: 4500,
                          isClosable: true,
                        })
                        throw new Error(res.status)
                      }
                    }).catch((error) => {
                      console.log(error);
                    })
                  }}>Submit</Button>
                ) : (activeStep !== 2 ? (
                  <Button colorScheme='blue' mr={3} onClick={() => setActiveStep(a => a + 1)}>
                    Next
                  </Button>
                ) : (
                  <Button mr={3} colorScheme='red' onClick={() => { setActiveStep(4) }}>No</Button>
                )
                )}
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Box overflowX="auto">
            <Table data={data} columns={columns} initialState={initialState} />
          </Box>
        </>
      )}
    </>
  )
}