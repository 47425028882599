import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    Button,
    Flex,
    Checkbox,
    Text,
    useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { CreatableSelect } from 'chakra-react-select';
import Loading from './components/loading';
import { useAuthState } from './components/AuthContext/authContext';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import Papa from 'papaparse';
const host = import.meta.env.VITE_REACT_APP_HOST;

function replace(myObj){
    Object.keys(myObj).forEach(function(key){
      if (typeof myObj[key] == 'object') { myObj[key] = JSON.stringify(myObj[key], null, 2)};
    });
    return myObj;
  }

export default function UserManagement() {
    const [users, setUsers] = useState();
    const [isLoading, setLoading] = useState(true);
    const [selectedUser, setSelected] = useState();
    const [uname, setName] = useState();
    const [email, setEmail] = useState();
    const [isSubmitting, setSubmitting] = useState(false);
    const [perfSubmitting, setperfSubmitting] = useState(false);
    const [perfget, setperfget] = useState(false);
    const [checkboxval, setcheckboxval] = useState({});
    const fetcher = url => fetch(url, { method: "GET", credentials: "include" }).then(res => res.json());
    const { data, isLoading: dataloading, mutate } = useSWR(host + '/api/getusers', fetcher);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate();
    const loggedinuser = useAuthState().user;
    const toast = useToast();

    useEffect(() => {
        if (!loggedinuser || !loggedinuser.permissions.usermanagement) {
            console.log(loggedinuser);
            navigate("/")
        }
        if (!dataloading) {
            console.log("Fetched!")
            setUsers(data);
            if (!selectedUser || !checkboxval) {
                console.log("Set!")
                setSelected(data[0]);
                setcheckboxval(data[0].permissions);
            }
            setLoading(false);
        };
    }, [loggedinuser, dataloading, data])

    const {
        register,
        handleSubmit,
        reset,
    } = useForm();
    const {
        register: srregister,
        handleSubmit: srhandleSubmit,
        reset: srreset,
    } = useForm();
    const options = ["payrollview", "payrollcreate", "returncreate", "returnconfirm", "usermanagement", "discountview", "viewalldiscounts", "viewsrdata", "viewAllsrdata", "purchase","deliveries", "viewallpayroll"];
    const userSubmit = async (values) => {
        setSubmitting(true);
        values['uname'] = uname;
        values['user'] = selectedUser;
        const submitvals = { ...values, ...checkboxval };
        await fetch(host + '/api/userupdate', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(submitvals)
        }).then(async (res) => {
            if (res.ok) {
                reset();
                const resusers = await res.json();
                console.log(resusers);
                setSelected(resusers);
                setcheckboxval(resusers.permissions);
                setSubmitting(false);
                mutate();
            } else {
                setSubmitting(false);
                toast({
                    title: "Error",
                    description: "That didn't work",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                mutate();
            }
        }).catch(error => {
            toast({
                title: "Error",
                description: JSON.stringify(error),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            setSubmitting(false);
            console.log(error);
            mutate();
        });
    }
    const downloaddata = async (values) => {
        console.log(values);
        setperfSubmitting(true);
        const perfsr = Object.keys(values).filter(key => values[key]).map(sr => data.find(user => user.name === sr));
        console.log(perfsr);
        const perfdata = Papa.unparse(perfsr.map(sr => sr.perfdata.map(object => replace(object))).flat());
        const csvdata = new Blob([perfdata], { type: 'text/csv' });
        const csvurl = window.URL.createObjectURL(csvdata);
        const testlink = document.createElement('a');
        testlink.href = csvurl;
        testlink.setAttribute('download', 'perfdata.csv');
        testlink.click();
        setperfSubmitting(false);
    }
    console.log(selectedUser)
    console.log(checkboxval);
    return (
        <>
            {isLoading ? <Loading /> : (
                <>
                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Create new User</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <FormControl name="name">
                                    <FormLabel>Name</FormLabel>
                                    <Input defaultValue={uname} onBlur={setName} />
                                </FormControl>
                                <FormControl name="email">
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email" onBlur={(values) => setEmail(values.target.value)} />
                                </FormControl>
                            </ModalBody>

                            <ModalFooter>
                                <Button onClick={() => { setUsers([...users, { name: uname, email: email }]); setSelected({ name: uname, email: email }); onClose() }}>Submit</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <Flex direction="column">
                        <Card>
                            <form onSubmit={handleSubmit(userSubmit)}>
                                <CardHeader>User Management</CardHeader>
                                <CardBody>
                                    <Flex direction="row">
                                        <FormControl isDisabled={isSubmitting}>
                                            <CreatableSelect placeholder='Select User' name="user" {...register('user')} defaultValue={{ label: selectedUser.name, value: selectedUser.email }} onCreateOption={(value) => { setName(value); onOpen(); }} onChange={(user) => {
                                                const selectuser = users.find(item => item.email === user.value)
                                                setSelected(selectuser);
                                                setcheckboxval(selectuser.permissions);
                                            }}
                                                options={users.map(user => {
                                                    const option = {
                                                        label: user.name,
                                                        value: user.email
                                                    };
                                                    return option;
                                                })}
                                            />
                                            {selectedUser ?
                                                (options.map((option, i) => <Checkbox p={2} key={i} name={option} {...register(option)} isChecked={checkboxval ? (checkboxval[option] ? true : false) : false} onChange={e => { const newval = checkboxval ? (checkboxval[e.target.name] ? false : true) : true; setcheckboxval(a => a ? { ...a, [e.target.name]: newval } : { [e.target.name]: newval }) }}>{option}</Checkbox>))
                                                : <Text>Select a user to display options</Text>}
                                        </FormControl>
                                    </Flex>
                                </CardBody>
                                <CardFooter>
                                    <Button isLoading={isSubmitting} type="submit">Submit</Button>
                                </CardFooter>
                            </form>
                        </Card>
                        <Card mt={2}>
                            <form onSubmit={srhandleSubmit(downloaddata)}>
                                <CardHeader>Sales Rep Performance</CardHeader>
                                <CardBody>
                                    <Flex direction="column">
                                        <Button colorScheme="green" isLoading={perfget} onClick={async () => {
                                            setperfget(true);
                                            fetch(host + '/admin/salesrepperf').then(res => {
                                                if (res.ok) {
                                                    setperfget(false);
                                                    toast({
                                                        title: "Success",
                                                        description: "Sales Rep Data Updated",
                                                        status: "success",
                                                        duration: 9000,
                                                        isClosable: true,
                                                    });
                                                    mutate();
                                                } else {
                                                    setperfget(false);
                                                    toast({
                                                        title: "Error",
                                                        description: "That didn't work",
                                                        status: "error",
                                                        duration: 9000,
                                                        isClosable: true,
                                                    });
                                                }
                                            })
                                        }}>Reload Sales Rep Data</Button>
                                        <Text>Select Reps to download raw data</Text>
                                        <FormControl isDisabled={isSubmitting}>
                                            <Flex direction="column">
                                                {data.filter(user => user.hasdata).map((user, i) => <Checkbox p={2} key={i} name={user.name} {...srregister(user.name)}>{user.name} - {user.lmtotal} new customers last month</Checkbox>)}
                                            </Flex>
                                        </FormControl>
                                    </Flex>
                                </CardBody>
                                <CardFooter>
                                    <Button isLoading={perfSubmitting} type="submit">Download Data</Button>
                                </CardFooter>
                            </form>
                        </Card>
                    </Flex>
                </>
            )}
        </>
    )
}