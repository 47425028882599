import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { CTable as Table } from './components/table';
import dayjs from 'dayjs';
import Big from 'big.js';
import {
    Text,
    Checkbox,
    Flex,
    Stack,
    CardFooter,
    Card,
    CardHeader,
    CardBody,
    Box,
    Heading,
    Button,
    StackDivider,
    Input,
    Tooltip,
    FormLabel,
    FormControl,
    useDisclosure,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    useToast,
    Container,
} from '@chakra-ui/react';
import Loading from './components/loading';

const host = import.meta.env.VITE_REACT_APP_HOST;

export default function Payroll() {
    const [isLoading, setLoading] = useState(true);
    const [checked, setChecked] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [data, setData] = useState([]);
    const [srdata, setSRData] = useState();
    const [secondcustomer, setSecondCustomer] = useState(false);
    const [payrolldate, setPayrollDate] = useState("10/01/2023");
    const [checkeditems, setCheckedItems] = useState({});
    const [secondcustomersubmit, setSCS] = useState(false);
    const [salesreptotals, setSRTotals] = useState({});
    const [groupArrayObject, setGAO] = useState();
    const [pyramid, setPyramid] = useState();
    const [bonuspayroll, setBonusPayroll] = useState([]);
    const {
        isOpen,
        onClose,
        onOpen,
    } = useDisclosure();
    const toast = useToast();
    const navigate = useNavigate();
    const columns = useMemo(() => [
        {
            Header: "Select",
            Cell: ({ row }) => {
                return (
                    <Checkbox name={row.original.CustomerName} isChecked={checkeditems[row.original.CustomerName] ?? false} onChange={(event) => {
                        if (event.target.checked) {
                            const newarray = checkeditems;
                            newarray[row.original.CustomerName] = true;
                            setCheckedItems(newarray);
                            setChecked(current => [...current, row.original]);
                        } else {
                            console.log("Unchecking...")
                            const newarray = checkeditems;
                            newarray[row.original.CustomerName] = false;
                            setCheckedItems(newarray);
                            setChecked(current => current.filter(item => item !== row.original))
                        }
                    }
                    } />
                )
            }
        },
        {
            Header: "Customer",
            accessor: "CustomerName"
        },
        {
            Header: "Sales Rep",
            accessor: "SalesRep"
        },
        {
            Header: "Sales Total",
            accessor: "Sum",
            sortDescFirst: true,
            sortType: "alphanumeric"
        }
    ], [checkeditems])

    useEffect(() => {
        console.log("in useEffect!");
        if (submitting === false) {
            console.log("fetching...")
            fetch(host + '/api/getpayrollinfo').then(async (res) => {
                const returnval = await res.json();
                setData(returnval.returnpayroll);
                console.log("success...");
                console.log(returnval);
                setPayrollDate(returnval.payrolldate);
                //returnval has a bonuspayroll property that is an array of customers. Display the customer, the total revenue of the customer, and the bonus amount for the customer. Sort customers by sales rep. Also display the total bonus amount for the sales rep.
                const groupArray = returnval.bonuspayroll.reduce((group, arr) => {
                    const { SalesRepresentative } = arr;
                    group[SalesRepresentative] = group[SalesRepresentative] ?? [];
                    group[SalesRepresentative].push(arr);
                    return group;
                }, {})
                setBonusPayroll(groupArray);
            });
        }
    }, [submitting])
    
    useEffect(() => {
        const newchecked = data.reduce((acc, item) => {
            if(Big(item.Sum).gte(200)){
                acc.push(item);
            }
            return acc;
        },[])
        const newcheckeditems = data.reduce((acc,item)=>{
            if(Big(item.Sum).gte(200)){
                acc[item.CustomerName] = true;
            } else {
                acc[item.CustomerName] = false;
            }
            return acc;
        },{})
        console.log("New Checked: ");
        console.log(newchecked);
        console.log("New Checked Items: ");
        console.log(newcheckeditems);
        setChecked(newchecked);
        setCheckedItems(newcheckeditems);
        setLoading(false);    
    },[data])

    useEffect(() => {
        const gao = checked.reduce((group, arr) => {
            const { SalesRep } = arr;
            group[SalesRep] = group[SalesRep] ?? [];
            group[SalesRep].push(arr);
            return group;
        }, {})
        let temppyramid = 0;
        const newsrtotal = Object.keys(gao).reduce((srtotal,curr) => {
            console.log(srtotal);
            console.log(curr);
            if(curr === "Brandon Benson"){
                const mcustomers = gao[curr].length ? gao[curr][1].SalesRepData.MonthlyCustomers : 0;
                if(gao[curr].length + mcustomers > 12){
                    srtotal[curr] = Big(192307 + 25000 * (gao[curr].length - 12)).div(100).toNumber();
                    temppyramid += 50*(gao[curr].length);
                } else {
                    srtotal[curr] = Big(192307).div(100).toNumber();
                    temppyramid += 50*(gao[curr].length);
                };
            } else {
            srtotal[curr] = 200 * gao[curr].length;
            temppyramid += 50*gao[curr].length;
            }
            return srtotal;
        },salesreptotals);
        console.log(newsrtotal);
        setGAO(gao);
        setSRTotals(newsrtotal);
        setPyramid(temppyramid);
    },[checked])
    return (
            <Flex>
            {isLoading ? (
                <Loading />
            ) : (
                <Flex direction={{base:"column", lg:"row"}} align="start">
                    <Card mb={2} mr={8}>
                        <CardHeader><Heading size="sm">Customers with unpaid Phase 1 Commissions</Heading></CardHeader>
                        <CardBody>
                            <Table data={data} columns={columns} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader><Heading size="sm">Selected Customers to Payout</Heading></CardHeader>
                        <CardBody>
                            <FormControl>
                                <FormLabel>Payroll Date:</FormLabel>
                                <Tooltip label="This is automatically set to the last payroll period end date">
                                    <Input type="date" value={payrolldate} onChange={e => setPayrollDate(e.target.value)} />
                                </Tooltip>
                            </FormControl>
                            <Stack divider={<StackDivider />} spacing='4'>
                                <Heading size="xs">First Time Customers - by SR:</Heading>
                                {Object.keys(groupArrayObject).map((item,i) => {
;                                    return (
                                        <Box key={i}>
                                            <Heading size="xs">{item}:</Heading>
                                            {groupArrayObject[item].map((cust,j) => {
                                                return (<Text key={j}>{cust.CustomerName}: ${cust.Sum}</Text>)
                                            })
                                            }
                                            {//Display bonus payroll info for each sales rep   
                                            bonuspayroll[item] && bonuspayroll[item].map((bonuscust,k) => {
                                                return (
                                                    <Box mb={2} key={k}>
                                                        <Text>{bonuscust.Name}: ${bonuscust.totalRevenue}</Text>
                                                        <Text as='b'>Bonus: ${Big(bonuscust.totalRevenue).div(100).toNumber()}</Text>
                                                    </Box>
                                                )
                                            })
                                        }
                                         {//Display total bonus payroll for each sales rep
                                            !!bonuspayroll[item] && <Text>Bonus Total: ${bonuspayroll[item].reduce((acc,curr) => Big(acc).plus(Big(curr.totalRevenue).div(100).toNumber()).toNumber(),0)}</Text>
                                            }
                                            {(item === "Scott Burgess") && <Text>Pyramid: {salesreptotals["Scott Burgess"] - groupArrayObject["Scott Burgess"].reduce((acc, curr) => Big(acc).plus(curr.Sum).toNumber(), 0)}</Text>}
                                            {/* remove second customers for now
                                            {(secondcustomer && srdata.some(customer => customer.SalesRepresentative === item)) && (
                                                <Box>
                                                    <Heading size="sm">Ongoing Customers:</Heading>
                                                    {srdata.map((customer,k) => {
                                                        if (customer.SalesRepresentative === item) {
                                                            return (
                                                                <Box key={k}>
                                                                    <Text as='b'>{customer.Name}</Text>
                                                                    <Text>Payment Sequence:   {customer.Commission2}</Text>
                                                                    <Text>Last order date:    {dayjs(customer.LastPurchaseDate).format("MM/DD/YYYY")}</Text>
                                                                </Box>
                                                            )
                                                        } else {
                                                            return
                                                        }
                                                    }
                                                    )}
                                                </Box>)} */}
                                            <Text>Total: {!!bonuspayroll[item] ? salesreptotals[item] + bonuspayroll[item].reduce((acc,curr) => Big(acc).plus(Big(curr.totalRevenue).div(100).toNumber()).toNumber(),0) : salesreptotals[item]}</Text>
                                            <Text>Total Customers: {groupArrayObject[item].length}</Text>
                                        </Box>
                                    )
                                })}

                                {//If the sales rep doesn't exist in groupArrayObject, then display the sales rep and the total bonus payroll for the sales rep
                                Object.keys(bonuspayroll).filter(item => !Object.keys(groupArrayObject).includes(item)).map((item,i) => {
                                    return (
                                        <Box key={i}>
                                            <Heading size="xs">{item} - Bonus Only:</Heading>
                                            {bonuspayroll[item].map((cust,j) => {
                                                return (
                                                    <Box mb={2} key={j}>
                                                        <Text>{cust.Name}: ${cust.totalRevenue}</Text>
                                                        <Text as="b">Bonus: ${Big(cust.totalRevenue).div(100).toNumber()}</Text>
                                                    </Box>
                                                )
                                            })}
                                            <Text>Total Bonus: ${bonuspayroll[item].reduce((acc,curr) => Big(acc).plus(Big(curr.totalRevenue).div(100).toNumber()).toNumber(),0)}</Text>
                                        </Box>
                                    )
                                })
                                }
                               <Box>
                                    <Heading size="xs">Scott Burgess - Management:</Heading>
                                    <Text>Management: {pyramid}</Text>
                                    <Text>Total (Management + Commission): {pyramid + (+salesreptotals["Scott Burgess"] || 0)}</Text>
                                </Box>
                                {/* remove second customers for now
                                {secondcustomer ? (
                                    <Box>
                                        <Button onClick={() => {
                                            srdata.forEach(customer => setSRTotals(current => { current[customer.SalesRepresentative] -= 50; return current; }));
                                            setSRData({});
                                            setSecondCustomer(false);
                                        }}>Remove Ongoing Customers from Payroll Run</Button>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Button isLoading={secondcustomersubmit} onClick={async () => {
                                            setSCS(true);
                                            const scdata = await fetch(host + "/api/secondcustomers?date=" + payrolldate, {
                                                method: "GET",
                                            }).then(res => res.json());
                                            setSRData(scdata);
                                            console.log(scdata);
                                            if (scdata && scdata.length > 0) {
                                                scdata.forEach(customer => setSRTotals(current => {
                                                    if(!current[customer.SalesRepresentative]){
                                                        current[customer.SalesRepresentative] = 50;
                                                        setGAO(gao => {
                                                            gao[customer.SalesRepresentative] = [];
                                                            return gao;
                                                        });
                                                        return current;
                                                    } else {
                                                        current[customer.SalesRepresentative] += 50;
                                                        return current;
                                                    }
                                                }));
                                                setSecondCustomer(true);
                                            } else {
                                                onOpen();
                                            }
                                            setSCS(false);
                                        }}>Add Ongoing Customers to Payroll Run</Button>
                                    </Box>
                                )} */}

                                {isOpen && (
                                    <Alert status='warning'>
                                        <AlertIcon />
                                        <Box>
                                            <AlertTitle>No Data</AlertTitle>
                                            <AlertDescription>
                                                We are not tracking any ongoing commission payments.
                                            </AlertDescription>
                                        </Box>
                                        <CloseButton
                                            alignSelf='flex-start'
                                            position='relative'
                                            right={-1}
                                            top={-1}
                                            onClick={onClose}
                                        />
                                    </Alert>
                                )}

                            </Stack>
                        </CardBody>
                        <CardFooter>
                            <Button onClick={() => {
                                setSubmitting(true);
                                console.log(groupArrayObject);
                                const submitarr = {groupArrayObject: groupArrayObject, salesreptotals: salesreptotals, srdata: srdata, bonuspayroll: bonuspayroll};
                                console.log(submitarr);
                                fetch(host + "/api/payrollupdate", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify(submitarr)
                                }).then(res => {
                                    if (res.ok) {
                                        setSubmitting(false);
                                        toast({
                                            title: 'Success',
                                            description: 'Payroll Info Recorded',
                                            status: 'success',
                                            duration: 4500,
                                            isClosable: true,
                                        })
                                    }
                                });
                            }}>Pay</Button>
                        </CardFooter>
                    </Card>
                </Flex>
            )}
            </Flex>)
}
