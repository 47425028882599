import useSWR from 'swr';

export const fetcher = url => fetch(url,{method: "GET", credentials:"include"}).then(r => r.json());
export default function useSKU(){
    const host = import.meta.env.VITE_REACT_APP_HOST;
    const { data, error, isLoading } = useSWR(host + "/api/getdeardata", fetcher,{revalidateOnFocus: false})
    
    return {
      swrdata:data,
      isLoading,
      isError: error
    }
  }