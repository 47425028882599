import React from "react";
import Returns from "./returns";
import { Link } from "react-router-dom";
import ReturnManager from "./returnmanager";
import {
    Tabs,
    Tab,
    TabList,
    TabPanel,
    Text,
    TabPanels,
    Flex,
    Button
} from '@chakra-ui/react'
import { useAuthState } from "./components/AuthContext/authContext";
import Order from './NewOrder'
import { preload } from "swr";
import Deliveries from "./deliveries";
const host = import.meta.env.VITE_REACT_APP_HOST;

const fetcher = url => fetch(url,{method:"GET", credentials:"include"}).then(res => res.json());
preload(host + "/api/getsalelist", fetcher);

export default function ReturnTabs(){
const user = useAuthState().user;
    return(
        <Flex w="100%" p={2}>
            <Tabs isLazy w="100%">
            <TabList>
                <Tab>Create New Order</Tab>
                <Tab>Orders</Tab>
                {user.permissions.returnconfirm && <Tab>Receive Return</Tab>}
                {user.permissions.deliveries && <Tab>Deliveries</Tab>}
            </TabList>
            <TabPanels>
            <TabPanel>
                <Order />
            </TabPanel>
            <TabPanel>
                <Returns />
            </TabPanel>
            <TabPanel minW={200}>
                {user.permissions.returnconfirm ? <ReturnManager /> : <Text>You don't have access to this module!</Text>}
            </TabPanel>
            <TabPanel>
                {user.permissions.deliveries ? <Deliveries />: <Text>You don't have access to this module!</Text>}
            </TabPanel>
            </TabPanels>
        </Tabs>
    </Flex>
    )
}