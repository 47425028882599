import React, { useMemo } from "react";
import {
    Flex,
    Stack,
    Heading,
    useToast,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Radio, 
    RadioGroup
} from '@chakra-ui/react';
import NewCustomer from "./NewCustomer";
import useSKU from "./components/fetchsku";
import { CTable as Table } from './components/table'

export default function Customers() {

    return (
        <Flex direction="column" w="100%" p={2}>
            <Heading m={5} alignItems="center" justifyContent="center" textAlign="center"> Customers </Heading>
            <Tabs isLazy w="100%">
                <TabList>
                    <Tab>New Customer Setup</Tab>
                    <Tab>Customer List</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <NewCustomer />
                    </TabPanel>
                    <TabPanel>
                        <CustomerList />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    )
}

function CustomerList() {
    const toast = useToast();
    const host = import.meta.env.VITE_REACT_APP_HOST;
    const {swrdata, isLoading} = useSKU();
    const columns = useMemo(() => [
        {
            Header:'Customer Name',
            accessor:'Name',
            sortDescFirst:'true',
        },
        {
            Header:'Phone Number',
            accessor:'phone'
        },
        {
            Header:'Sales Representative',
            accessor:'SalesRepresentative',
            style: { 'white-space': 'unset' }
        },
        {
            Header:'Payment Method',
            accessor:'Pmethod',
            Cell:({cell}) => {
                const [value, setValue] = React.useState(cell.row.original.Pmethod)
                const onBlur = () => {
                    const data = {
                        customer:cell.row.original.id,
                        Pmethod:value
                    };
                      fetch(host+"/api/customerpaymentmethod",{
                        headers:{
                            'Content-Type':"application/json"
                        },
                        method:"POST",
                        body:JSON.stringify(data)
                      }).then(res => {
                        if(res.ok){
                            toast({
                                title: "Success",
                                description: "Payment method for "+cell.row.original.Name+" was successfully updated",
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                            })
                            mutatecp();
                        } else {
                            toast({
                                title: "Error",
                                description: "Payment Method Submission Failed",
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                      })
                    }
                  
                    // If the initialValue is changed external, sync it up with our state
                    React.useEffect(() => {
                      setValue(cell.row.original.Pmethod)
                    }, [cell.row.original.Pmethod])

              return(
                <RadioGroup onBlur={onBlur} name="pmethod" onChange={setValue} value={value}>
                <Stack spacing={4} direction='row'>
                  <Radio value='CHECK'>Check</Radio>
                  <Radio value='QBACH'>QB Payments - ACH</Radio>
                  <Radio value='QBCARD'>QB Payments - Card</Radio>
                  <Radio value='STRIPE'>Stripe</Radio>
                  <Radio value='ACH'>ACH/Zelle</Radio>
                </Stack>
              </RadioGroup>
              )
            }
        }],[swrdata])
        const data = useMemo(() => swrdata.customers,[swrdata]);

        return(
            <Table data={data} columns={columns} />
        )
}