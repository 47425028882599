import React, { useState, useEffect} from 'react';
import {Routes, Route, Link as RouterLink, useNavigate} from 'react-router-dom';
import ProductInfo from './intproducts';
import ReturnTabs from './returntabs';
import Test from './test';
import Logo from './Primary_BlueGreen.svg';
import Payroll from './payroll'
import { preload } from 'swr';
import {
  chakra,
  Container,
  Button,
  Text,
  Flex,
  Divider,
  List,
  ListItem,
  Link,
  Image,
  createIcon,
  Spacer
} from '@chakra-ui/react';
import {
  ExternalLinkIcon
} from '@chakra-ui/icons';
import { useAuthState } from './components/AuthContext/authContext';
import UserManagement from './usermanage';
import MobileDrawer from './components/mobiledrawer';
import SRDash from './salesrepdashboard';
import Customers from './customers';
import Order from './NewOrder';
import Purchases from './purchases';
import Receive from './receive';
const host = import.meta.env.VITE_REACT_APP_HOST;
const fetcher = url => fetch(url, { method: "GET", credentials: "include" }).then(res => res.json());
preload(host + "/api/getdeardata", fetcher);

export default function AuthenticatedApp() {
  const navigate = useNavigate();
  const user = useAuthState().user;
  const headerdata = [
    {
      label: 'Home',
      dest: '/'
    },
    {
      label: 'Orders',
      dest: '/order'
    },
    {
      label: 'Customers',
      dest: '/customer'
    },
    {
      label: 'Product Info',
      dest: '/productinfo'
    },
    ...user.permissions.purchase ? [{
      label: 'Purchases',
      dest: '/purchases'
    }]:[],
    ...user.permissions.viewAllsrdata ? [{
      label: 'Sales Rep Dashboard',
      dest: '/srdash'
    }]:[],
    ...user.permissions.payrollview ? [{
      label: 'Payroll',
      dest: '/payroll'
    }]:[],
    ...user.permissions.usermanagement ? [{
      label: 'User Management',
      dest: '/usermanagement'
    }]:[],

  ];
  const IconicMarkGreen = createIcon({
    displayName: "IconicMarkGreen",
    viewBox: "0 0 2469 2748",
    d: "M1238.01 274L275 935.141l199.95 140.319 501.71-344.405 261.35-179.464 558.74 383.55-1.98 1.361 201.01 139.028 198.6-140.383L1238.01 274zm1.15 400.899v520.231l378.97-260.031-378.97-260.2zm468.5 321.32l-469.65 322.301-469.37-322.177L563.227 1137.4l674.783 473.62 672.76-475.5-203.11-139.301zM2195 1067.27l-956.99 656.72v310.79l956.99-657v-310.51zm-.02 433.09l-956.97 656.86v313.19l3.74 2.59 24.18-21.71 929.03-637.82.02-313.11z",
  });
  const { logOut } = useAuthState();
  console.log(headerdata);
  return (

    <Flex direction="column" align="center" w="100%">
      <chakra.header id="header" w="100%">
        <MobileDrawer headerdata={headerdata}/>
        <Flex
          w="100%"
          px="6"
          py="5"
          mb={10}
          align="center"
          grow={1}
          display={{base:"none", md:"flex"}}
        >
          <Flex minWidth='max-content' width="100%" alignItems='start' gap='2'>
            <IconicMarkGreen color="#9ACF44" boxSize={10} />
            {headerdata.map((item, i) => (
              <Link as={RouterLink} to={item.dest} key={i}>
                <Button variant="nav"> {item.label} </Button>
              </Link>
            ))}
            <Spacer />
            <Text pt={2}>Version: {__APP_VERSION__}</Text>
            <Button variant="nav" onClick={() => { logOut(); navigate(0) }}>Log Out</Button>
          </Flex>
        </Flex>
      </chakra.header>

      <Routes>
        <Route path="/srdash" element={<SRDash />} />
        <Route path="/neworder" element={<Order />} />
        <Route path="/customer" element={<Customers />} />
        <Route path="/productinfo" element={<ProductInfo />} />
        <Route path="/order" element={<ReturnTabs />} />
        <Route path="/test" element={<Test />} />
        <Route path="/payroll" element={<Payroll />} />
        <Route path="/usermanagement" element={<UserManagement />} />
        <Route path="/purchases" element={<Purchases />} />
        <Route path="/receive" element={<Receive />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Flex>
  );
}

const linklistdata = [
  {
    href: 'https://www.quickbooks.com',
    title: 'Quickbooks'
  },
  {
    href: 'https://www.mycarriertms.com',
    title: 'MyCarrier TMS'
  },
  {
    href: 'https://www.expensify.com/authentication/saml/login',
    title: 'Expensify'
  },
  {
    href: 'https://fleetaccount.ford.com',
    title: 'Ford Pro Vehicle Tracking'
  },
  {
    href: 'https://www.hubspot.com',
    title: 'Hubspot'
  },
  {
    href: 'https://runpayroll.adp.com',
    title: 'Payroll'
  },
  {
    href: 'https://inventory.dearsystems.com',
    title: 'DEAR Inventory'
  },
  {
    href: 'https://station.airlocklogistics.com',
    title: 'Airlock Inventory System'
  }
];

function Home() {
  const user = useAuthState().user;
  return (
    <Flex grow={2} mt={10} direction="column" justify="flex-start">
      <Container align="center">
        <Image src={Logo} />
      </Container>
      <Container maxW='container.md' mt={10} align="center">
      <Text>Welcome {user.name}</Text>
        <Divider />
        {user.permissions.viewsrdata ? 
        <SRDash /> : <List align="start">
        {linklistdata.map(item => {
          return <ListItem key={item.title}><Link href={item.href} isExternal>{item.title}<ExternalLinkIcon mx='2px' /></Link></ListItem>
        })}
      </List>
        }
      </Container>
    </Flex>
  );
}
/*   function validateaddress(address){
    return new Promise((resolve, reject) =>{
      address.countryCode = 'US';
      fetch(host + "/api/valaddress", {
        method:"POST",
        headers: {
          'Content-Type':'application/json',
        },
        body:JSON.stringify(address)
      }).then(res => {
          console.log(res);
          res.json().then(result => {
          console.log(result);
          if (result[0].status === 'verified') {
            var validatedaddress = result[0].normalizedAddress;
            let addresstransform = {};
            addresstransform.line1 = validatedaddress.addressLine1;
            addresstransform.line2 = validatedaddress.addressLine2;
            addresstransform.city = validatedaddress.cityLocality;
            addresstransform.state = validatedaddress.stateProvince;
            addresstransform.zip = validatedaddress.postalCode;
            resolve(addresstransform);
          } else {
            alert("This address is not valid:", result);
            reject(new Error("Invalid Address"));
          }
      })
      .catch((error) => {
        console.log(error);
      })
  
 */