import React, { createContext, useContext } from "react"
import useSWR from "swr"
import { googleLogout } from '@react-oauth/google';
import * as Sentry from "@sentry/react";


const AuthContext = createContext();
const host = import.meta.env.VITE_REACT_APP_HOST;

export const AuthProvider = ({children}) => {
    const fetcher = url => fetch(url,{method: "GET", credentials:"include"}).then(r => {
        if(!r.ok) {
            const error = new Error('An Error occurred with Authentication')
            error.status = r.status;
            throw error;
        }
        return r.json();
    }).then(userdata => {
        Sentry.setUser({ email: userdata.email})
        return userdata;
    });
    const { data, error, mutate } = useSWR(host + "/api/v1/auth/me", fetcher)
    const handleLogin = async googleData => {
        console.log(googleData)
        await fetch(host + "/api/v1/auth/google", {
            method: "POST",
            credentials:"include",
            body: JSON.stringify({
                token: googleData.credential
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }).catch(error => {
            console.log(error);
        })
        mutate()
    }

    const logOut = async () => {
        await fetch(host + "/api/v1/auth/logout", {
            credentials:"include",
            method: "DELETE"
        });
        googleLogout()
        Sentry.setUser(null);
        mutate()
    }

    return(
        <AuthContext.Provider value={{
            user: data,
            error: error,
            handleLogin,
            logOut,
            mutate
        }}> {children} </AuthContext.Provider>
    );
};

export const useAuthState = () => useContext(AuthContext);
