import React, { useState, useEffect, useMemo } from 'react';
import { useAuthState } from './components/AuthContext/authContext';
import {
    Flex,
    Card,
    CardBody,
    CardHeader,
    Text,
    Skeleton,
    Stack
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import useSKU from './components/fetchsku';
import dayjs from 'dayjs';
import { CTable as Table } from './components/table';
import DisplayPayrollHistory from './components/payrollhistory';
import { CSVLink } from 'react-csv';

export default function SRDash() {
    const loggedinuser = useAuthState().user;
    const navigate = useNavigate();
    const { swrdata, isLoading } = useSKU();
    const [custwarning, setCustomerWarn] = useState([]);
    const [custpayout, setCustomerPayout] = useState([]);
    const [csvdata, setCsvData] = useState([]);

    const columns = useMemo(
        () =>
            [
                {
                    Header: 'Customer',
                    accessor: 'Name',
                    style: { 'white-space': 'unset' }
                },
                {
                    Header: 'Last Purchase Date',
                    accessor: 'LastPurchaseDate',
                    sortDescFirst: 'true',
                    Cell: ({ cell }) => {
                        return dayjs(cell.value).format("MM/DD/YYYY");
                    },
                },
            ]
        , []
    );

    const csvHeaders = [
        { label: 'Customer', key: 'Name' },
        { label: 'Last Purchase Date', key: 'LastPurchaseDate' },
        { label: 'Line 1', key: 'pAddress.Line1'},
        { label: 'Line 2', key: 'pAddress.Line2'},
        { label: 'City', key: 'pAddress.City'},
        { label: 'State', key: 'pAddress.State'},
        { label: 'Zip', key: 'pAddress.Postcode'},
        { label: 'Sales Rep', key: 'SalesRepresentative'},
        { label: 'phone', key: 'phone'},
        { label: 'email', key: 'email'},
        { label: "Contact Name", key: 'dcontact'},
    ];


    useEffect(() => {
        if (!loggedinuser || (!loggedinuser.permissions.viewsrdata && !loggedinuser.permissions.viewAllsrdata)) {
            console.log(loggedinuser);
            navigate("/")
        }
    }, [loggedinuser])

    useEffect(() => {
        if (!isLoading) {
            if (loggedinuser.permissions.viewAllsrdata) {
                setCustomerWarn(swrdata.customers.filter(customer => dayjs(customer.LastPurchaseDate).isBefore(dayjs().subtract(3, 'month'))).sort((a, b) => {
                    const adate = dayjs(a.LastPurchaseDate);
                    const bdate = dayjs(b.LastPurchaseDate);
                    if (adate.isAfter(bdate)) {
                        return -1;
                    } else {
                        return 1;
                    }
                }));
                setCustomerPayout(swrdata?.customers.filter(customer => customer.totalRevenue));
                setCsvData(swrdata.customers);
            } else {
                setCustomerWarn(swrdata?.customers.filter(customer => customer.SalesRepresentative === loggedinuser.name).filter(customer => dayjs(customer.LastPurchaseDate).isBefore(dayjs().subtract(3, 'month'))).sort((a, b) => {
                    const adate = dayjs(a.LastPurchaseDate);
                    const bdate = dayjs(b.LastPurchaseDate);
                    if (adate.isAfter(bdate)) {
                        return -1;
                    } else {
                        return 1;
                    }
                }));
                setCustomerPayout(swrdata?.customers.filter(customer => customer.SalesRepresentative === loggedinuser.name).filter(customer => customer.totalRevenue));
            }
        }
    }, [swrdata, isLoading])
    return (
        <Flex>
            <Card m={2}>
                <CardHeader>
                    Dormant Customers (No orders past three months)
                </CardHeader>
                <CardBody>
                    <Skeleton isLoaded={!isLoading}>
                        <Table data={custwarning} columns={columns} />
                        {loggedinuser.permissions.viewAllsrdata && <CSVLink data={csvdata} headers={csvHeaders} filename={"dormantcustomers.csv"}>Download Data Dump</CSVLink>}
                    </Skeleton>
                </CardBody>
            </Card>
            <Card m={2}>
                <CardHeader>
                    Customers Eligble for Revenue Bonus
                </CardHeader>
                <CardBody>
                    <Stack>
                        <Skeleton isLoaded={!isLoading} height='20px' />
                        <Skeleton isLoaded={!isLoading} height='20px' />
                        <Skeleton isLoaded={!isLoading} height='20px' />
                    </Stack>
                        {custpayout?.map((customer,i) => {
                            return loggedinuser.permissions.viewAllsrdata ?
                            <Text key={i}>Customer: {customer.Name} SalesRep: {customer.SalesRepresentative} Payout: {customer.totalRevenue * 0.01} Total Revenue: {customer.totalRevenue}</Text> :
                            <Text key={i}>Customer: {customer.Name} </Text>
                            }
                        )}
                </CardBody>
            </Card>
            <Card m={2}>
                <CardHeader>
                    Payroll History
                </CardHeader>
                <CardBody>
                        <DisplayPayrollHistory />
                </CardBody>
            </Card>
        </Flex>
    )


}