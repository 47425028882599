import React, {useState} from 'react';
import {
    Box,
    Input,
    Button
} from '@chakra-ui/react';
const host = import.meta.env.VITE_REACT_APP_HOST;


export default function Test(){
const [value, setValue] = useState();
console.log(value);
return(
<Box>
    <Input onChange={e => setValue(e.target.value)}/>
    <Button type="submit" onClick={() => {
        fetch(host + "/api/test", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({value: value}),
          }).then(res => res.json()).then(value => {
            alert(value);
          }
          )
    }}>Submit</Button>
</Box>
)
}