import React, { useEffect, useState, useMemo } from "react";
import dayjs from 'dayjs';
import isSameorBefore from 'dayjs/plugin/isSameOrBefore';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure,
    Flex,
    Heading,
    Text,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    useToast,
    Card,
    CardHeader,
    CardBody,
    Input,
    FormControl,
    FormLabel,
    Checkbox,
    FormErrorMessage,
    Spacer
} from '@chakra-ui/react';
import { CTable as Table } from './components/table';
import LoadingModal from "./components/loadingmodal";
import useSWR from "swr";
import { Form, useNavigate } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import PDFMerger from 'pdf-merger-js/browser';
import { Page, Text as PDFText, View, Document, StyleSheet, pdf, PDFDownloadLink } from '@react-pdf/renderer';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
const host = import.meta.env.VITE_REACT_APP_HOST;

dayjs.extend(isSameorBefore);

export default function Deliveries() {
    const [orders, setOrders] = useState([]);
    const [dataloading, setLoading] = useState(true);
    const [doloading, setDOLoading] = useState(false);
    const { data: salelist, isLoading, mutate } = useSWR(host + "/orders");
    const [step2data, setStep2Data] = useState([]);
    const [doprogress, setDOProgress] = useState();
    const [needdimensions, setNeedDimensions] = useState([]);
    const [createDocument, setCreateDocument] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isDOOpen, onOpen: onDOOpen, onClose: onDOClose } = useDisclosure();
    const navigate = useNavigate();
    const toast = useToast();
    const steps = [
        { title: 'First', description: 'Outstanding Orders' },
        { title: 'Second', description: 'Delivery Orders' },
        { title: 'Third', description: 'Invoices' },
        { title: 'Review', description: 'Confirmation' },
    ];
    const { activeStep, setActiveStep } = useSteps({
        index: 1,
        count: steps.length,
    });
    const vibevehicles = {
        "121": "Ford Transit",
        "211": "Sprinter",
        "111": "Ford Transit Connect"
    };
    useEffect(() => {
        if (!isLoading) {
            console.log(salelist);
            const beforetoday = salelist.filter(item => dayjs(item.Shipby).isSameOrBefore(dayjs()));
            salelist.forEach(item => {
                if (item.needdimensions && item.needdimensions.length) {
                    setNeedDimensions(current => [...current, item.needdimensions.filter(product => !current.includes(product))].flat());
                }
            })
            setOrders(beforetoday);
            setLoading(false);
        }
    }, [salelist]);

    const defaultvals = useMemo(() => needdimensions.map(product => {
        return {
            product: product,
            height: 0,
            width: 0,
            length: 0,
            weight: 0
        }
    }), [needdimensions]);
    const schema = yup.object().shape({
        productdimensions: yup.array().of(
            yup.object().shape({
                product: yup.string().required("A product is required"),
                height: yup.number().required("Height is required"),
                width: yup.number().required("Width is required"),
                length: yup.number().required("Length is required"),
                weight: yup.number().required("Weight is required")
            })
        )
    });

    const { control, register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            productdimensions: defaultvals
        },
        resolver: yupResolver(schema)
    });
    const { fields } = useFieldArray({
        control,
        name: "productdimensions"
    })
    useEffect(() => {
        reset({ productdimensions: defaultvals });
    }, [defaultvals]);
    console.log(fields);
    console.log(orders);
    const columns = useMemo(
        () =>
            [
                {
                    Header: "Add to List",
                    Cell: ({ cell }) => {
                        return (
                            <Checkbox isChecked={orders.some(order => order.SaleID === cell.row.original.SaleID)} onChange={(value) => {
                                if (!orders.some(order => order.SaleID === cell.row.original.SaleID)) {
                                    setOrders(current => [...current, cell.row.original]);
                                } else {
                                    setOrders(current => current.filter(item => item.SaleID !== cell.row.original.SaleID));
                                }
                            }} />
                        )
                    }
                },
                {
                    Header: 'Ship By Date',
                    accessor: 'ShipBy',
                    sortDescFirst: 'true',
                    Cell: ({ cell }) => {
                        return dayjs(cell.value).format("MM/DD/YYYY");
                    },
                },
                {
                    Header: 'Customer',
                    accessor: 'Customer[0].Name',
                    style: { 'white-space': 'unset' }
                },
                {
                    Header: 'Order Number',
                    accessor: 'Order.SaleOrderNumber'
                },
                {
                    Header: 'Volume (cu. ft.)',
                    accessor: 'load'
                }
            ]
        , [orders]
    );
    const submitOrders = async () => {
        setDOLoading(true);
        setDOProgress({ text: "Generating Delivery Plan", value: 10 });
        console.log(orders);
        const response = await fetch(host + "/orders/generate", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(orders)
        }).catch(error => {
            console.log(error);
            setDOLoading(false);
            setDOProgress();
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        });
        if (response.ok) {
            setDOProgress({ text: "Routing Vehicles", value: 30 });
            const data = await response.json();
            if (data.solution.status === "success") {
                console.log("Got the solution!")
                console.log(data);
                setDOProgress({ text: "Creating Pick List", value: 60 })
                fetch(host + "/orders/pick", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(orders)
                }).catch(error => {
                    console.log(error);
                    setDOLoading(false);
                    setDOProgress();
                    toast({
                        title: "Error",
                        description: error.message,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                });
                setStep2Data(data.solution);
                const styles = StyleSheet.create({
                    page: {
                        flexDirection: 'column',
                        alignItems: 'center',
                    },
                    section: {
                        margin: 10,
                        padding: 10,
                        flexGrow: 1,
                        textAlign: 'center',
                        flexDirection: 'column'
                    }
                });
                setDOProgress({ text: "Creating Delivery Orders", value: 90 });
                console.log(data.solution.solution);
                const createDocument1 = () => (
                    <Document>
                        {Object.keys(data.solution.solution).map((vehicle, i) => {
                            if (data.solution.solution[vehicle].length === 2) {
                                return;
                            } else {
                                return (
                                    <Page size="LETTER" style={styles.page} wrap key={i}>
                                        <View style={styles.section} wrap>
                                            <PDFText>Vehicle: {vibevehicles[vehicle]}</PDFText>
                                            <PDFText>Driver: _____________________</PDFText>
                                        </View>
                                        <View style={styles.section} wrap>
                                            {data.solution.solution[vehicle].map((location, j) =>
                                                <PDFText key={j}>Stop: {location.location_name}  Arrival Time: {location.arrival_time}</PDFText>
                                            )}
                                        </View>
                                    </Page>
                                )
                            }
                        })}
                    </Document>
                );
                console.log(createDocument1);
                setCreateDocument(createDocument1);
                setActiveStep(2);
                setDOLoading(false);
                setDOProgress();
            }
        } else {
            setDOLoading(false);
            toast({
                title: "Error",
                description: "There was an error generating delivery orders",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
    }
    const submitDimensions = async (data) => {
        console.log(data);
        const response = await fetch(host + "/orders/updatedimensions", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        if (response.ok) {
            onClose();
            setNeedDimensions([]);
            setActiveStep(1);
            toast({
                title: "Product Data Updated",
                description: "Product data has been updated",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
            mutate();
        }
    }

    const downloadDocuments = async (location) => {
        const downloaddata = await fetch(host + "/orders/download").then(res => res.json());
        console.log(location);
        console.log(downloaddata);
        if (location) {
            const locationlink = document.createElement('a');
            const locationurl = downloaddata.find(item => item.SaleID === location.notes).PickListURL;
            locationlink.href = locationurl;
            locationlink.setAttribute('download', `${location.location_name}.pdf`);
            document.body.appendChild(locationlink);
            locationlink.click();
            return;
        }
        const merger = new PDFMerger();
        await Promise.all(downloaddata.map(async (item) => {
            if (item.PLPrinted) {
                return;
            }
            const file = await fetch(host + "/proxy/file", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ url: item.PickListURL })
            });
            const blob = await file.blob();
            console.log("1")
            return await merger.add(blob);
        }));
        console.log("2")
        await merger.setMetadata({
            producer: "VC Int"
        });
        console.log(createDocument);
        const blob = await pdf(createDocument).toBlob();
        await merger.add(blob);
        console.log(merger);
        await merger.save("DeliveryOrders");
    }
    // Write a function below that calls /orders/invoices and gets a list of invoice urls. Then use the urls to download the invoices, merge them into one file, and save to user's computer.
    const generateInvoices = async () => {
        const response = await fetch(host + "/orders/invoices");
        const data = await response.json();
        console.log(data);
        const merger = new PDFMerger();
        await Promise.all(data.map(async (item) => {
            const file = await fetch(host + "/proxy/file", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ url: item.InvoiceURL })
            });
            const blob = await file.blob();
            return await merger.add(blob);
        }));
        await merger.save("Invoices");
    }
    const emailNotification = async () => {
        const response = await fetch(host + "/orders/emailnotification");
        if (response.ok) {
            toast({
                title: "Email Sent",
                description: "Email notification has been sent",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
        }
        setActiveStep(4);
    };
    return (
        <Flex w="100%">
            {(dataloading ? <LoadingModal isOpen={dataloading} text="Loading Orders..." value={null} /> :
                (<Flex direction="column" w="100%">
                    <LoadingModal isOpen={dataloading || doloading} text={doprogress ? doprogress.text : "Loading..."} value={(doprogress && doprogress.value) ? doprogress.value : null} />
                    <Modal size="xl" isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Update Product Data</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <form id="propertydimensions" onSubmit={handleSubmit(submitDimensions)}>
                                    {fields.map((item, index) => (
                                        <Flex key={item.id} direction="column" borderWidth="1px" borderRadius='lg' p={4} m={2}>
                                            <FormControl>
                                                <FormLabel>Product</FormLabel>
                                                <Input variant="unstyled" {...register(`productdimensions.${index}.product`)} isReadOnly />
                                                <FormErrorMessage>{errors.productdimensions?.[index]?.product?.message}</FormErrorMessage>
                                            </FormControl>
                                            <Flex mt={2}>
                                                <FormControl m={2}>
                                                    <FormLabel>Length (in)</FormLabel>
                                                    <Input {...register(`productdimensions.${index}.length`)} />
                                                    <FormErrorMessage>{errors.productdimensions?.[index]?.length?.message}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl m={2}>
                                                    <FormLabel>Width (in)</FormLabel>
                                                    <Input {...register(`productdimensions.${index}.width`)} />
                                                    <FormErrorMessage>{errors.productdimensions?.[index]?.width?.message}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl m={2}>
                                                    <FormLabel>Height (in)</FormLabel>
                                                    <Input {...register(`productdimensions.${index}.height`)} />
                                                    <FormErrorMessage>{errors.productdimensions?.[index]?.height?.message}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl m={2}>
                                                    <FormLabel>Weight (lbs)</FormLabel>
                                                    <Input {...register(`productdimensions.${index}.weight`)} />
                                                    <FormErrorMessage>{errors.productdimensions?.[index]?.weight?.message}</FormErrorMessage>
                                                </FormControl>
                                            </Flex>
                                        </Flex>
                                    ))}
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                <Button form="propertydimensions" colorScheme="blue" mr={3} type="submit">
                                    Submit
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <Stepper index={activeStep}>
                        {steps.map((step, index) => (
                            <Step key={index}>
                                <StepIndicator>
                                    <StepStatus
                                        complete={<StepIcon />}
                                        incomplete={<StepNumber />}
                                        active={<StepNumber />}
                                    />
                                </StepIndicator>
                                <Box flexShrink='0'>
                                    <StepTitle>{step.title}</StepTitle>
                                    <StepDescription>{step.description}</StepDescription>
                                </Box>
                                <StepSeparator />
                            </Step>
                        ))}
                    </Stepper>
                    {!!needdimensions.length &&
                        <Alert status="error" mb={2}>
                            <AlertIcon />
                            <AlertTitle mr={2}>Missing Product Dimensions</AlertTitle>
                            <AlertDescription>
                                There are products that are missing dimensions and weights. Update Product Data <Button onClick={onOpen}>here</Button> before proceeding.
                            </AlertDescription>
                        </Alert>
                    }
                    {(() => {
                        switch (activeStep) {
                            case 1:
                                return (
                                    <Box>
                                        <Flex direction="column" align="center" w="100%">
                                            <Button colorScheme="blue" isDisabled={!!needdimensions.length} onClick={submitOrders}>Generate Delivery Orders</Button>
                                            <Box w="100%">
                                                <Table data={salelist} columns={columns} />
                                            </Box>
                                        </Flex>
                                    </Box>
                                )
                            case 2:
                                return (
                                    <Box>
                                        <Heading>Delivery Orders</Heading>
                                        {!!step2data.num_unserved &&
                                            <Alert status="error">
                                                <AlertIcon />
                                                <AlertTitle mr={2}>Unserved Orders</AlertTitle>
                                                <AlertDescription>
                                                    There are {step2data.num_unserved} unserved orders for the following reasons:
                                                    {Object.keys(step2data.unserved).map((index) => {
                                                        return (
                                                            <Text key={index}>{index}: {step2data.unserved[index]}</Text>
                                                        )
                                                    })}
                                                </AlertDescription>
                                            </Alert>
                                        }
                                        <Box w="100%">
                                            <Flex>
                                                <Button m={2} colorScheme="blue" onClick={() => downloadDocuments()}>Download Pick List and Delivery Plan</Button>
                                                <PDFDownloadLink document={createDocument} fileName="DeliveryPlan.pdf">
                                                    {({ loading }) =>
                                                        <Button colorScheme="blue">
                                                            {loading ? 'Loading document...' : 'Download Delivery Plan Only'}
                                                        </Button>
                                                    }
                                                </PDFDownloadLink>
                                                <Spacer />
                                                <Button m={2} colorScheme="blue" onClick={() => setActiveStep(3)}>Confirm and Generate Invoices</Button>
                                            </Flex>
                                            <Flex w="100%" align="start" justify="center" grow>
                                                {Object.keys(step2data.solution).map((vehicle, i) => {
                                                    if (step2data.solution[vehicle].length === 2) {
                                                        return;
                                                    } else {
                                                        return (
                                                            <Card m={2} key={i}>
                                                                <CardHeader>
                                                                    <Heading>Vehicle: {vibevehicles[vehicle]}</Heading>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Flex direction="column">
                                                                        <Text m={2}><b>Start Time:</b> 10:00 am</Text>
                                                                        {step2data.solution[vehicle].map((location, j) => {
                                                                            if (location.arrival_time === "10:00") {
                                                                                return;
                                                                            } else {
                                                                                return (
                                                                                    <Flex ml={2} key={j}>
                                                                                        <Text><b>Stop {j}: </b>{location.location_name}  <b>Arrival Time:</b> {location.arrival_time}</Text>
                                                                                        {location.location_name !== "San Diego Warehouse" && <Button ml={2} colorScheme="blue" size="xs" onClick={() => { downloadDocuments(location.location_name) }}>Download</Button>}
                                                                                    </Flex>
                                                                                )
                                                                            }
                                                                        }
                                                                        )}
                                                                    </Flex>
                                                                </CardBody>
                                                            </Card>
                                                        )
                                                    }
                                                }
                                                )}
                                            </Flex>
                                        </Box>
                                    </Box>
                                )
                            case 3:
                                return (
                                    <Box>
                                        <Heading>Invoices</Heading>
                                        <Flex>
                                            <Button m={2} colorScheme="blue" onClick={generateInvoices}>Download Invoices</Button>
                                            <Spacer />
                                            <Button m={2} colorScheme="blue" onClick={emailNotification}>Send Notification</Button>
                                        </Flex>
                                    </Box>
                                )
                            case 4:
                                // return a confirmation page with a button to go home
                                return (
                                    <Box>
                                        <Heading>Done.</Heading>
                                        <Button colorScheme="Blue" onClick={() => navigate('/')}>Home</Button>
                                    </Box>
                                )
                        }
                    })(activeStep)}
                </Flex>
                )
            )}
        </Flex>
    )
}