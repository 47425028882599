import { Modal, ModalOverlay, Flex, ModalContent, ModalBody, CircularProgress, CircularProgressLabel, Center, Text } from '@chakra-ui/react'

//Write a function that returns a chakra model that shows a loading spinner. The function should take in a variable that determines whether the modal is open or not and a separate variable that displays text beneath the spinner. The modal should be centered on the screen and should be able to be closed by clicking outside of the modal.
export default function LoadingModal({ isOpen, text, value }) {
  return (
    <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex direction="column" align="center">
            <Flex>
            <CircularProgress isIndeterminate={!value} value={value}>
                <CircularProgressLabel>{value}</CircularProgressLabel>
            </CircularProgress>
            </Flex>
            <Text>{text}</Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}