//Write a component that queries the payroll history of a user and displays it in a table. The component should display a loading modal while the data is being fetched. The component should also display an error message if the data fails to load.
//When click on the payroll date, the component should show a breakdown of the payroll. Specifically, payrolldata[0] is an object with the sales rep name as the key and an array of objects as the value. We should only show the information matching the current logged in user unless the user has viewallpayroll permissions. The objects in the array consist of the keys "SUM", "SalesRep", and "Customer Name". Only Customer Name needs to be displayed here.
//payrolldata[1] is the total payroll for each sales rep. It is an object with the sales rep name as the key.
//payrolldata[2] if it exists contains an array of objects with the keys "Name", Commission2, LastPurchaseDate, and SalesRepresentative. We should transform this into a list of names based on the sales representative and return the list corresponding to the current logged in user unless the user has viewallpayroll permissions in which case we return all the data.

import React, { useState, useEffect } from 'react';
import { useAuthState } from '../components/AuthContext/authContext';
import LoadingModal from './loadingmodal';
import { CTable } from './table';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Heading, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';

export default function DisplayPayrollHistory(){
    const [payrollHistory, setPayrollHistory] = useState([]);
    const [payrollData, setPayrollData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const { user:currentUser } = useAuthState();
    const [payrollBreakdown, setPayrollBreakdown] = useState();
    const {isOpen, onOpen, onClose} = useDisclosure();

    //This should be columns according to React-Table v7 and it should display data from the payrollhistory table
    const payrollcolumns = [
        {
            Header: 'Payroll Date',
            accessor: 'payroll_date',
            Cell: ({row}) => {
                return <a href="#" onClick={() => displayPayrollBreakdown(row.original.payrollid)}>{row.original.payroll_date}</a>
            }

        },
        {
            Header: 'Total Payroll',
            accessor: 'total_payroll'
        }
    ];

    //If current user has viewallpayroll permissions, we should display the payrollhistory for all users. Otherwise, we should only display the payrollhistory for the current user.
    useEffect(() => {
        fetch(__host__+'/payroll/history')
        .then(res => res.json())
        .then(data => {
            const payrolldata = data.map(payroll => {
                return {
                    payrollid: payroll.ID,
                    payroll_date: dayjs(payroll.payrolldate).format('MM/DD/YYYY'),
                    total_payroll: currentUser.permissions.viewallpayroll ? Object.values(payroll.payrolldata[1]).reduce((acc, val) => acc+val) : payroll.payrolldata[1][currentUser.name]
                };
            });
            setPayrollData(data);
            setPayrollHistory(payrolldata);
            setLoading(false);
        })
        .catch(err => {
            setError('Failed to load payroll history');
            setLoading(false);
        });
    }, []);

    const displayPayrollBreakdown = (payrollid) => {
        const payroll = payrollData.find(payroll => payroll.ID === payrollid);
        const payrollcustomers = currentUser.permissions.payrollview ? Object.keys(payroll.payrolldata[0]).map(salesrep => payroll.payrolldata[0][salesrep]).flat(Infinity) : [];
        const payrollsecond = payroll.payrolldata.length > 2 ? currentUser.permissions.viewallpayroll ? payroll.payrolldata[2] : payroll.payrolldata[2].filter(customer => customer.SalesRepresentative === currentUser.name) : null;
        setPayrollBreakdown({customers: payrollcustomers, secondpay: payrollsecond});
        onOpen();
    }


    if(loading){
        return <LoadingModal isOpen={loading} text="Loading payroll history" />
    }

    if(error){
        return <div>{error}</div>
    }


    if(isOpen) {
        if (currentUser.permissions.payrollview == 'true' ? true : false) {
            return (
                <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Payroll Breakdown</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Heading>New Customers</Heading>
                            <CTable data={payrollBreakdown.customers} columns={[{Header: 'Customer Name', accessor: 'CustomerName'},{Header:'Sales Rep', accessor: 'SalesRep'}]} />
                            <Heading>Residuals</Heading>
                            {payrollBreakdown.secondpay ? <CTable data={payrollBreakdown.secondpay} columns={[{Header: 'Name', accessor: 'Name'},{Header: 'Sequence', accessor: 'Commission2'},{Header: 'Sales Representative', accessor: 'SalesRepresentative'}]} /> : <Text>No Residuals Paid Out This Payroll</Text> }
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )
        }else{
            return(
                <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>You don't have permission to see payroll details</ModalHeader>
                    </ModalContent>
                </Modal>
            )
        }
        
    }

    //This should return something using the CTable component
    return (
        <CTable data={payrollHistory} columns={payrollcolumns} />
    )
}