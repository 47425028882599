import React, {useEffect, useMemo, useState} from 'react';
import {
    Flex,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Skeleton,
    Table,
    Tbody,
    Tr,
    Th,
    Td,
    FormControl,
    Input
} from '@chakra-ui/react'
import {useAuthState} from './components/AuthContext/authContext';
import { useNavigate, useLocation } from "react-router-dom";
import {useForm, Controller, useFieldArray} from "react-hook-form";
import { CTable } from './components/table';


export default async function Receive(){
    const location = useLocation();
    const container = location.state.container;

    const {
        register,
        handleSubmit,
        setValue,
        formState:{errors,dirtyFields, isSubmitting},
        reset,
        control,
      } = useForm();

    const columns = useMemo(() => [
        {
            Header:"SKU",
            accessor:"sku"
        },
        {
            Header:"Quantity Received",
            Cell:(cell) => {
                return <FormControl><Input key={cell.row.index} {...register(`skuarray.${cell.row.index}.Qty`)} /></FormControl>
            }
        }
    ],[]);
    
    const onSubmit = async(data) => {
        await fetch(host + "/api/receive", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.ok) {
                onClose();
                toast({
                    title: "Success",
                    description: "Received!",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
            }
        })
    }

    return(
        <Flex>
            <Card>
                <CardHeader>{container.Name}</CardHeader>
                <CardBody>
                    <Table>
                        <Tbody>
                            <Tr>
                                <Th>Container Number</Th>
                                <Td>{container.ContainerNumber}</Td>
                            </Tr>
                            <Tr>
                                <Th>Total Quantity</Th>
                                <Td>{container.TotalQuantity}</Td>
                            </Tr>
                            <Tr>
                                <Th>Status</Th>
                                <Td>{container.Status}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                    <Text>Order Data:</Text>
                    <form id="receiveform" onSubmit={handleSubmit(onSubmit)}>
                        <CTable columns={columns} data={container.orderdata} />
                        <Button type="submit">Submit</Button>
                    </form>
                </CardBody>
            </Card>
        </Flex>
    )
}