import React, { useState, useEffect, Suspense } from 'react';
import {
  Spinner,
  Textarea,
  Spacer,
  Card,
  CardBody,
  Input,
  InputGroup,
  InputLeftAddon,
  Button,
  Box,
  Flex,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  VStack,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  Modal,
  ModalBody,
  useToast,
  Switch,
  Divider,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import useSKU from './components/fetchsku';
import useSWRImmutable from 'swr/immutable';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { default as GooglePlacesAutocomplete, geocodeByPlaceId } from 'chakra-ui-google-places-autocomplete';
import { Select } from 'chakra-react-select';
import { useAuthState } from './components/AuthContext/authContext';
import GetCardDetails from './getcardetails';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const host = import.meta.env.VITE_REACT_APP_HOST;
const stripePromise = loadStripe(import.meta.env.VITE_REACT_APP_STRIPE_PUBLIC_KEY);


export default function NewCustomer() {
  const [salesreplist, setSalesRepList] = useState();
  const [defaultsalesrep, setDefaultSalesrep] = useState();
  const { user } = useAuthState();
  const fetcher = url => fetch(url, { method: "GET", credentials: "include" }).then(r => r.json());
  const { data, isLoading } = useSWRImmutable(host + "/api/getsalesrep", fetcher);
  const { swrdata, isLoading: dataLoading } = useSKU();
  const [editCustomer, setEditCustomer] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  const [CustomerReturn, setCustomerReturn] = useState(false);
  const [autopay, setAutoPay] = useBoolean(true);
  const [initemail, setInitEmail] = useBoolean(true);
  const [pod, setPOD] = useBoolean(false);
  const { isOpen: SpinnerOpen, onOpen: OpenSpinner, onClose: CloseSpinner } = useDisclosure();
  const toast = useToast();
  const updateAddress = (addressval,index) => {
    geocodeByPlaceId(addressval.value.place_id).then(results => {
      console.log(results);
      const addresscomp = results[0].address_components;
      let streetnumber;
      let street;
      let city;
      let state;
      let zip;
      for (let i = 0; i < addresscomp.length; i++) {
        const comp = addresscomp[i];
        if (comp.types.includes("street_number")) {
          streetnumber = comp.long_name;
        } else if (comp.types.includes("route")) {
          street = comp.long_name;
        } else if (comp.types.includes("locality")) {
          city = comp.long_name;
        } else if (comp.types.includes("administrative_area_level_1")) {
          state = comp.short_name;
        } else if (comp.types.includes("postal_code")) {
          zip = comp.long_name;
        }
      }
      const addressline1 = streetnumber + " " + street;
      const addressobj = {
        line1: addressline1,
        city: city,
        state: state,
        zip: zip
      }
      if(typeof index !== 'undefined' && index !== null){
        console.log("Index Exists!")
        setValue(`address.${index}`, addressobj);
      } else {
        setValue("paddress", addressobj)
      }
    })
  }

  let initialsalesrep;

  const stripeoptions = {
    mode: 'setup',
    currency: 'usd',
  }

  useEffect(() => {
    if (!isLoading) {
      console.log(isLoading);
      let salesrepdata = [];
      for (let j = 0; j < data.salesRep.length; j++) {
        salesrepdata.push({ value: data.salesRep[j].Name, label: data.salesRep[j].Name });
      }
      for (let i = 0; i < data.salesRep.length; i++) {
        if (user.email === data.salesRep[i].Email) {
          initialsalesrep = { value: data.salesRep[i].Name, label: data.salesRep[i].Name };
        }
      }
      if (initialsalesrep == null) {
        initialsalesrep = { value: "Scott Burgess", label: "Scott Burgess" };
      }
      console.log(salesrepdata);
      setDefaultSalesrep(initialsalesrep);
      setValue("salesrep", initialsalesrep);
      setSalesRepList(salesrepdata);
    };
  }, [data, isLoading])


  const customerSubmit = async (values) => {
    OpenSpinner()
    console.log(values);
    const salesrepemail = data.salesRep.find(salerep => salerep.Name === values.salesrep.value).Email;
    values['toemail'] = [user.email, salesrepemail, "ar@vibecartons.com"];
    values['edit'] = editCustomer;
    values['initemail'] = initemail;
    values['pod'] = pod;
    values['autopay'] = autopay;
    console.log(JSON.stringify(values));
    await fetch(host + "/api/newcustomer", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    }).then(async (res) => {
      if (res.status === 200) {
        const body = await res.json();
        if (values.initemail) {
          reset();
          setValue("cselect",null);
          toast({
            title: "Customer Created",
            description: "Email to Customer Sent. Please follow up with Customer",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          CloseSpinner();
        } else {
          await fetch(host + "/api/getctoken", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
          }).then(res => res.json()).then(response => {
            setClientSecret(response);
            setCustomerReturn(true);
          });
        }
      } else {
        alert(res.status + res.statusText);
        throw new Error(res.status)
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const schema = Yup.object({
    dinstruct: Yup.string()
      .trim()
      .max(1024, 'Must be 1024 characters or less'),
    cname: Yup.string()
      .max(256, 'Must be 256 characters or less')
      .required('Required')
      .trim(),
    dcontact: Yup.string()
      .max(256, 'Must be 256 characters or less')
      .required('Required')
      .trim(),
    salesrep: Yup.object()
      .required('Required')
    /* bcontact: Yup.string()
    .max(256, 'Must be 256 characters or less')
    .trim()
    .when(['bemail','bphone'],{
      is: (bemail, bphone) => {
        return(bemail || bphone)
      },
      then: () => Yup.string().required("You must fill in Billing Contact if Billing Email or Billing Phone is provided!")}), */
    //Billing Area is broken, oh well.
  })


  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, isSubmitting },
    reset,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      'cname': "",
      'dcontact': "",
      'lbn': "",
      'cuemail': "",
      'phone': "",
      'bcontact': "",
      'salesrep': defaultsalesrep,
      'paddress': {
        line1: "",
        line2: "",
        city: "",
        state: "",
        zip: ""
      },
    },
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "address"
  });
/*   const { bfields, bappend, bremove } = useFieldArray({
    control,
    name: "baddress"
  })
 */
  console.log(getValues("cselect"));
  console.log(autopay);
  return (
    <Suspense fallback={<h2>Loading...</h2>}>
      {isLoading || dataLoading ? (
        <Flex grow={1} align="center" justify="center">
          <Spinner />
          <p>Loading...</p>
        </Flex>
      ) : (
        <Flex justify="center">
          <VStack spacing={4}>
            <Modal isOpen={SpinnerOpen} onClose={CloseSpinner}>
              <ModalOverlay />
              <ModalBody>
                <ModalContent padding={10}>
                  {!CustomerReturn ? (
                    <Flex alignItems="center" justify="center" direction="column">
                      <Spacer />
                      <Spinner m={2} />
                      <Box>
                        <p>Submitting...</p>
                      </Box>
                    </Flex>) : (
                    <Elements stripe={stripePromise} options={stripeoptions}>
                      <GetCardDetails mode="setup" clientSecret={clientSecret.client_secret} customer={clientSecret.customer} onClose={() => { 
                        reset(); 
                        CloseSpinner(); 
                        
                        }} host={host} />
                    </Elements>
                  )}
                </ModalContent>
              </ModalBody>
            </Modal>
            <form onSubmit={handleSubmit(customerSubmit)}>
              <Card boxShadow='lg' mt={5}>
                <CardBody padding={10}>
                  <FormControl name="cselect" isDisabled={isSubmitting}>
                    <FormLabel>Customer Select (If Creating New Customer, Leave this field blank.)</FormLabel>
                    <Controller
                      control={control}
                      name="cselect"
                      render={({ field }) => (
                        <Select useBasicStyles options={swrdata.customers.map(o => ({ value: o.CustomerID, label: o.Name }))} {...field} value={field.value} onBlur={field.onBlur} name={field.name} onChange={value => {
                          const cdata = swrdata.customers.find(customer => customer.Name === value.label);
                          console.log(cdata);
                          const resaddress = cdata.dAddress.map(address => {
                            if(address.Line1 !== null && address.Line1 !== undefined){
                              return {
                                line1:address.Line1,
                                line2:address.Line2,
                                city:address.City,
                                state:address.State,
                                zip:address.Postcode,
                                AddressID:address.ID,
                                dinstruct:cdata.ShippingNoteArray?.find(note => note.ID === address.ID)?.dinstruct,
                              }
                            }
                          })
                          if(!cdata.pAddress){
                            cdata.pAddress = cdata.dAddress[0];
                          }
                          const paddress = {
                            line1:cdata.pAddress.Line1,
                            line2:cdata.pAddress.Line2,
                            city:cdata.pAddress.City,
                            state:cdata.pAddress.State,
                            zip:cdata.pAddress.Postcode,
                            AddressID:cdata.pAddress.ID,
                          }
                          reset({
                            cselect: value,
                            cname: cdata.Name,
                            lbn: cdata.LBN,
                            salesrep: { value: cdata.SalesRepresentative, label: cdata.SalesRepresentative },
                            cuemail: cdata.email,
                            phone: cdata.phone,
                            mphone: cdata.mphone,
                            paddress:paddress,
                            dinstruct: cdata.ShippingNote,
                            dcontact: cdata.dcontact,
                            ContactID: cdata.contactid,
                            address:resaddress,
                            ohours: cdata.Hours,
                          })
                          setInitEmail.off();
                          cdata.pod ? setPOD.on() : setPOD.off();
                          console.log(cdata.AutoPay);
                          cdata.AutoPay ? setAutoPay.on() : setAutoPay.off();
                          field.onChange(value);
                          setEditCustomer(true);
                        }} />)}
                    />
                  </FormControl>
                  <HStack>
                    <FormControl name="cname" isRequired isInvalid={!!errors.cname && dirtyFields.cname} isDisabled={isSubmitting}>
                      <FormLabel>Customer Name</FormLabel>
                      <Input name="cname" key="cname" {...register('cname')} />
                      <FormErrorMessage>{errors?.cname?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl name="lbn" isInvalid={!!errors.lbn && dirtyFields.lbn} isDisabled={isSubmitting}>
                      <FormLabel>Legal Business Name</FormLabel>
                      <Input name="lbn" placeholder="If different from Customer Name" {...register('lbn')} />
                      <FormErrorMessage>{errors?.lbn?.message}</FormErrorMessage>
                    </FormControl>
                  </HStack>
                  <FormControl isDisabled={isSubmitting} isRequired isInvalid={!!errors.salesrep && dirtyFields.salesrep}>
                    <FormLabel>Sales Representative:</FormLabel>
                    <Controller
                      name="salesrep"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select useBasicStyles isRequired name="salesrep" {...field} options={salesreplist} value={field.value} onChange={field.onChange} />
                      )}
                    />
                    <FormErrorMessage>{errors?.salesrep?.message}</FormErrorMessage>
                  </FormControl>
                  <HStack>
                    <FormControl name='cuemail' isDisabled={isSubmitting}>
                      <FormLabel>Customer Email</FormLabel>
                      <Input name="cuemail" type="email" {...register('cuemail')} />
                    </FormControl>
                    <FormControl name='phone' isDisabled={isSubmitting}>
                      <FormLabel>Phone</FormLabel>
                      <Input name="phone" type="tel" {...register('phone')} />
                    </FormControl>
                    <FormControl name='mphone' isDisabled={isSubmitting}>
                      <FormLabel>Mobile Phone</FormLabel>
                      <Input name="mphone" placeholder="optional" type="tel" {...register('mphone')} />
                    </FormControl>
                  </HStack>
                  <FormControl name="ohours" isDisabled={isSubmitting}>
                    <FormLabel>Open Hours</FormLabel>
                    <Input placeholder="HH:MM - HH:MM" name="ohours" {...register('ohours')} />
                  </FormControl>
                  <FormControl name="ContactId" isReadOnly >
                    <Input name="ContactID" hidden {...register('ContactID')} />
                  </FormControl>
                  <FormControl label="Delivery Contact" name="dcontact" isInvalid={!!errors.dcontact && dirtyFields.dcontact} isDisabled={isSubmitting}>
                    <FormLabel>Delivery Contact</FormLabel>
                    <Input name="dcontact" {...register('dcontact')} />
                    <FormErrorMessage>
                      {errors?.dcontact?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mt={2} name="paddress" isDisabled={isSubmitting}>
                      <FormControl name="paddress.AddressID" isReadOnly >
                        <Input name="paddress.AddressID" hidden {...register("paddress.AddressID")} />
                      </FormControl>
                      <FormLabel>Primary Address</FormLabel>
                      <GooglePlacesAutocomplete apiKey="AIzaSyCiU5MYMffo_VyT9JhpvnuVOTicSPZNijs" selectProps={{ useBasicStyles: true, name: "address", onChange: (value) => updateAddress(value) }} autocompletionRequest={{ componentRestrictions: { country: ['us'] } }} />
                      <InputGroup mt={1}>
                        <InputLeftAddon>Address Line 1</InputLeftAddon>
                        <Input name="paddress.line1" {...register("paddress.line1")} />
                      </InputGroup>
                      <InputGroup mt={1}>
                        <InputLeftAddon>Address Line 2</InputLeftAddon>
                        <Input name="paddress.line2" {...register("paddress.line2")} />
                      </InputGroup>
                      <HStack mt={1} spacing={0}>
                        <InputGroup mr={2}>
                          <InputLeftAddon>City</InputLeftAddon>
                          <Input name="paddress.city" {...register("paddress.city")} />
                        </InputGroup>
                        <InputGroup mr={2}>
                          <InputLeftAddon>State</InputLeftAddon>
                          <Input name="paddress.state" {...register("paddress.state")} />
                        </InputGroup>
                        <InputGroup>
                          <InputLeftAddon>Zip</InputLeftAddon>
                          <Input name="paddress.zip" {...register("paddress.zip")} />
                        </InputGroup>
                      </HStack>
                    </FormControl>
                    <FormControl mt={2} name="dinstruct" isDisabled={isSubmitting}>
                        <FormLabel>Primary Address Instructions Instructions</FormLabel>
                        <Textarea name="dinstruct" placeholder="These instructions will be printed on the delivery order for the primary address" {...register("dinstruct")} />
                      </FormControl>
                  {fields.map((item, index) => (
                    <FormControl mt={2} key={item.id} isDisabled={isSubmitting}>
                      <Divider />
                      <FormControl name={`address.${index}.AddressID`} isReadOnly >
                        <Input name={`address.${index}.AddressID`} hidden {...register(`address.${index}.AddressID`)} />
                      </FormControl>
                      <FormLabel>{`Delivery Address ${index+1}`}</FormLabel>
                      <GooglePlacesAutocomplete apiKey="AIzaSyCiU5MYMffo_VyT9JhpvnuVOTicSPZNijs" selectProps={{ useBasicStyles: true, name: `address.${index}.autocomplete`, onChange: (value) => {console.log(index);updateAddress(value,index)} }} autocompletionRequest={{ componentRestrictions: { country: ['us'] } }} />
                      <InputGroup mt={2}>
                        <InputLeftAddon>Address Line 1</InputLeftAddon>
                        <Input name={`address.${index}.line1`} {...register(`address.${index}.line1`)} />
                      </InputGroup>
                      <InputGroup mt={2}>
                        <InputLeftAddon>Address Line 2</InputLeftAddon>
                        <Input name={`address.${index}.line2`} {...register(`address.${index}.line2`)} />
                      </InputGroup>
                      <HStack mt={2} spacing={0}>
                        <InputGroup mr={2}>
                          <InputLeftAddon>City</InputLeftAddon>
                          <Input name={`address.${index}.city`} {...register(`address.${index}.city`)} />
                        </InputGroup>
                        <InputGroup mr={2}>
                          <InputLeftAddon>State</InputLeftAddon>
                          <Input name={`address.${index}.state`} {...register(`address.${index}.state`)} />
                        </InputGroup>
                        <InputGroup>
                          <InputLeftAddon>Zip</InputLeftAddon>
                          <Input name={`address.${index}.zip`} {...register(`address.${index}.zip`)} />
                        </InputGroup>
                      </HStack>
                      <FormControl mt={2} name={`address.${index}.dinstruct`} isDisabled={isSubmitting}>
                        <FormLabel>{`Address ${index+1} Instructions`}</FormLabel>
                        <Textarea name={`address.${index}.dinstruct`} placeholder="These instructions will be printed on the delivery order" {...register(`address.${index}.dinstruct`)} />
                      </FormControl>
                      <Button mt={2} onClick={() => remove(index)}>Delete Address</Button>
                      <Divider />
                    </FormControl>
                  ))}
                  <Button mt={2} onClick={() => append({AddressID:"", line1: "", line2: "", city: "", state: "", zip: "", dinstruct:"" })}>Add Address</Button>
                  <FormControl mt={2} name="initemail" isDisabled={isSubmitting} >
                    <FormLabel>Send Email to Customer to add Payment Method?</FormLabel>
                    <Switch name="initemail" isChecked={initemail} onChange={setInitEmail.toggle} />
                  </FormControl>
                  <Tooltip label="For example, customers that pay by Zelle or ACH Push should have both Autopay & POD set to false">
                  <FormControl name="pod" isDisabled={isSubmitting || initemail}>
                    <FormLabel>Require Driver to Collect Payment on Delivery</FormLabel>
                    <Switch name="pod" isChecked={pod} onChange={setPOD.toggle} />
                  </FormControl>
                  </Tooltip>
                  <FormControl name="autopay" isDisabled={isSubmitting || initemail}>
                    <FormLabel>Autopay</FormLabel>
                    <Switch name="autopay" isChecked={autopay} onChange={setAutoPay.toggle} />
                  </FormControl>
                  <Button type="submit" isLoading={isSubmitting} colorScheme='blue'>Submit</Button>
                </CardBody>
              </Card>
            </form>
          </VStack>
        </Flex>
      )}
    </Suspense>
  )
}