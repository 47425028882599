import React, { useMemo, useState, useEffect } from "react";
import {
    Flex,
    Heading,
    Text,
    Box,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Divider,
    useToast,
    Select,
    Input,
    Container,
} from '@chakra-ui/react';
import { CTable as Table } from './components/table';
import { Select as CRSelect } from 'chakra-react-select';
import useSWR from "swr";
import Loading from "./components/loading";
import { useForm, Controller } from "react-hook-form";
import useSKU from './components/fetchsku';
import CreateProductDiscount from "./productdiscount";
import { useAuthState } from "./components/AuthContext/authContext";
import { useNavigate } from "react-router-dom";

const host = import.meta.env.VITE_REACT_APP_HOST;

export default function Discounts() {
    const user = useAuthState().user;
    const navigate = useNavigate();
    const fetcher = url => fetch(url, { method: "GET", credentials: "include" }).then(res => res.json());
    const { data: odiscountlist, isLoading, mutate } = useSWR(host + "/api/discounts",fetcher);
    const { data: oproductdiscounts, isLoading:productloading, mutate:mutatecp} = useSWR(host+"/api/customprice",fetcher);
    const [discount, setDiscount] = useState();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [fdiscountlist, setFilterDiscount] = useState(odiscountlist);
    const [fproductdiscounts, setFProductDiscount] = useState(oproductdiscounts);

    useEffect(() => {
        if (!user.permissions.discountview){
            navigate("/");
        }
        if (!isLoading && !productloading) {
            if (user.permissions.viewalldiscounts){
                setFilterDiscount(odiscountlist);
                setFProductDiscount(oproductdiscounts);
            } else {
                console.log("Filtering List...")
                setFilterDiscount(odiscountlist.filter(discount => discount.Customer[0].SalesRepresentative === user.name));
                setFProductDiscount(oproductdiscounts.filter(discount => discount.Customer[0].SalesRepresentative === user.name));
            }
        }
    },[user,odiscountlist,oproductdiscounts, isLoading, productloading])
    function CreateDiscount() {
        let dvalue = {
            customer:""
        };
        if(discount){
            //If discount exists, overwrite values with discount;
            dvalue.customer = discount.customer;
            dvalue.discountvalue = discount.discountvalue;
            dvalue.discounttype = discount.discounttype;
            dvalue.discountfloor = discount.discountfloor;
            dvalue.floortype = discount.floortype;
        }
        const {
            handleSubmit,
            register,
            control,
            formState: { errors, isSubmitting },
        } = useForm({
            defaultValues:dvalue
        });
        const { swrdata, isLoading:useSKUloading } = useSKU();
        const [loadOptions, setCustomers] = useState();
        useEffect(() => {
            if(!useSKUloading){
                setCustomers(swrdata.customers.map(o => ({ value: o.CustomerID, label: o.Name })));
            }
        }, [swrdata, useSKUloading])
    
        const onSubmit = async (data) => {
            await fetch(host + "/api/discounts", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(res => {
                if (res.ok) {
                    onClose();
                    toast({
                        title: "Success",
                        description: "Changes Submitted",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                    })
                    mutate();
                }
            })
        }
        return (
            <>
                <Button isLoading={useSKUloading} m={2} onClick={() => onOpen()}>Order Level Discounts</Button>
                <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Order Level Discounts</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text>This form creates order level discounts for customers.</Text>
                            <form id="creatediscount" onSubmit={handleSubmit(onSubmit)}>
                                <FormControl isInvalid={errors.customer}>
                                    <FormLabel htmlFor='customer'>Select Customer:</FormLabel>
                                    <Controller
                                        name="customer"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => {
                                            return (
                                                <CRSelect
                                                    {...field}
                                                    isClearable
                                                    value={field.value}
                                                    options={loadOptions}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                />)
                                        }}
                                    />
                                </FormControl>
                                <Divider />
                                <Flex justify="start">
                                        <FormControl maxW={20} mr={2}>
                                            <Input {...register("discountvalue")} />
                                        </FormControl>
                                        <FormControl maxW={20} mr={2}>
                                            <Select {...register("discounttype")}>
                                                <option value='percent'>%</option>
                                                <option value='dollars'>$</option>
                                            </Select>
                                        </FormControl>
                                        <Flex pt={2} mr={2} maxW={40}>
                                        <Text>off</Text>
                                        </Flex>
                                        <FormControl maxW={40} mr={2}>
                                            <Select {...register("pertype")}>
                                                <option value="item">each item</option>
                                                <option value="order">entire order</option>
                                            </Select>
                                        </FormControl>
                                        <Flex pt={2} mr={2} maxW={40}>
                                        <Text> when order is</Text>
                                        </Flex>
                                        <FormControl maxW={20} mr={2}>
                                            <Input {...register("discountfloor")} />
                                        </FormControl>
                                        <FormControl maxW={40} mr={2}>
                                            <Select {...register("floortype")}>
                                                <option value="item">items</option>
                                                <option value="value">dollars</option>
                                            </Select>
                                        </FormControl>
                                        <Text pt={2} maxW={20}>or more</Text>
                                </Flex>
                            </form> 
                        </ModalBody>
                        <ModalFooter>
                        <Button form="creatediscount" type="submit" colorScheme="blue">Submit</Button>    
                        </ModalFooter>
                    </ModalContent>
    
                </Modal>
            </>
        )
    }

    const productcolumns = useMemo(
        () => [
            {
                Header:'Customer',
                accessor:'CustomerName',
                style:{
                    'white-space':'unset'
                }
            },
            {
                Header:'SKU',
                accessor:'SKU'
            },
            {
                Header:'Normal Price',
                accessor:'NormalPrice'
            },
            {
                Header:'Minimum Quantity',
                accessor:'Quantity',
                Cell:({cell}) => {
                    // We need to keep and update the state of the cell normally
                    const [value, setValue] = React.useState(cell.row.original.Quantity)
                  
                    const onChange = e => {
                      setValue(e.target.value)
                    }
                  
                    // We'll only update the external data when the input is blurred
                    const onBlur = () => {
                    const data = {
                        customer:cell.row.original.CustomerName,
                        products:[
                            {
                                sku:cell.row.original.SKU,
                                customprice:cell.row.original.Price,
                                qty:value
                            }
                        ]
                    };
                      fetch(host+"/api/customprice",{
                        headers:{
                            'Content-Type':"application/json"
                        },
                        method:"POST",
                        body:JSON.stringify(data)
                      }).then(res => {
                        if(res.ok){
                            toast({
                                title: "Success",
                                description: "Minimum Quantity for "+data.customer+"'s "+data.products[0].sku+" was successfully set to "+data.products[0].qty,
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                            })
                            mutatecp();
                        } else {
                            toast({
                                title: "Error",
                                description: "Custom Price Submission Failed",
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                      })
                    }
                  
                    // If the initialValue is changed external, sync it up with our state
                    React.useEffect(() => {
                      setValue(cell.row.original.Quantity)
                    }, [cell.row.original.Quantity])
                  
                    return <input value={value} onChange={onChange} onBlur={onBlur} />
                }
            },
            {
                Header:'Discounted Price',
                accessor:'Price',
                Cell:({cell}) => {
                        // We need to keep and update the state of the cell normally
                        const [value, setValue] = React.useState(cell.row.original.Price)
                      
                        const onChange = e => {
                          setValue(e.target.value)
                        }
                      
                        // We'll only update the external data when the input is blurred
                        const onBlur = () => {
                        const data = {
                            customer:cell.row.original.CustomerName,
                            products:[
                                {
                                    sku:cell.row.original.SKU,
                                    customprice:value,
                                    qty:cell.row.original.Quantity
                                }
                            ]
                        };
                          fetch(host+"/api/customprice",{
                            headers:{
                                'Content-Type':"application/json"
                            },
                            method:"POST",
                            body:JSON.stringify(data)
                          }).then(res => {
                            if(res.ok){
                                toast({
                                    title: "Success",
                                    description: "Custom Price for "+data.customer+"'s "+data.products[0].sku+" was successfully set to "+data.products[0].customprice,
                                    status: "success",
                                    duration: 9000,
                                    isClosable: true,
                                })
                            } else {
                                toast({
                                    title: "Error",
                                    description: "Custom Price Submission Failed",
                                    status: "error",
                                    duration: 9000,
                                    isClosable: true,
                                })
                            }
                          })
                        }
                      
                        // If the initialValue is changed external, sync it up with our state
                        React.useEffect(() => {
                          setValue(cell.row.original.Price)
                        }, [cell.row.original.Price])
                      
                        return <input value={value} onChange={onChange} onBlur={onBlur} />
                    }
            }
        ],[]
    )

    const columns = useMemo(
        () =>
            [
                {
                    Header: 'Customer',
                    accessor: 'CustomerName',
                    style: { 'white-space': 'unset' }
                },
                {
                    Header: 'Discount Description',
                    accessor: 'description'
                },
                {
                    Header: 'Action',
                    accessor: 'refund',
                    Cell: ({ cell }) => {
                        return (
                            <Flex>
                                <Button onClick={() => {setDiscount(cell.row.original); onOpen();}}>
                                    Edit
                                </Button>
                                <Button onClick={() => {
                                    fetch(host + "/api/deletediscount", {
                                        method: "PUT",
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify(cell.row.original.customer)
                                    }).then(() => mutate())
                                }}>
                                    Delete
                                </Button>
                            </Flex>
                        )
                    }
                }
            ]
        , []
    );
    const discountlist = useMemo(() => fdiscountlist,[fdiscountlist]);
    const productdiscounts = useMemo(() => fproductdiscounts, [fproductdiscounts]);
    return (
        <Container>
            {(isLoading || productloading) ? <Loading /> : (
                <>
                    <Box>
                        <Heading>Discounts</Heading>
                        <Text>Current active discount rules for each customer is shown here. Only one order level rule can exist for a customer.</Text>
                        <CreateDiscount />
                        <CreateProductDiscount />
                    </Box>
                    {discountlist?.length ? (
                        <Box overflowX="auto">
                        <Table data={discountlist} columns={columns} />
                        </Box>
                    ):(
                            <Text>No Discounts Found</Text>
                    )}
                    <Divider />
                    {productdiscounts?.length ? (
                        <Box overflowX="auto">
                        <Table data={productdiscounts} columns={productcolumns} /> 
                    </Box>
                    ):(
                        <Text>No Custom Prices Found</Text>  
                    )}
                    
                </>
            )}
        </Container>
    )
}