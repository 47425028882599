import { useState, useMemo, Suspense, useEffect, useRef } from 'react';
import {
  useDisclosure,
  Checkbox,
  Flex,
  Spinner,
  Heading,
  Text,
  Input,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Tooltip,
  Container,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useAuthState } from './components/AuthContext/authContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Select } from 'chakra-react-select';
import CustomerReq from './customerreq'
import { CTable as Table } from './components/table';
import Parse from './components/parse';
import useSKU from './components/fetchsku';
import Discounts from './discounts';
const host = import.meta.env.VITE_REACT_APP_HOST;

export default function ProductInfo() {
  const user = useAuthState().user;
    return(
        <Flex w="100%" p={2}>
            <Tabs isLazy w="100%">
            <TabList>
                <Tab>Interchangeable Product Info</Tab>
                {user.permissions.discountview && <Tab>Discounts</Tab>}
            </TabList>
            <TabPanels>
            <TabPanel>
                <IntProducts />
            </TabPanel>
            <TabPanel minW={200}>
                {user.permissions.discountview ? <Discounts /> : <Text>You don't have access to this module!</Text>}
            </TabPanel>
            </TabPanels>
        </Tabs>
    </Flex>
    )
}

function IntProducts() {
  const [dearsku, setDearData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { swrdata, isLoading } = useSKU();
  const [qty, setQty] = useState();
  const bskuselect = useRef();
  const [datastate, setData] = useState([]);
  const [dataloading, setdataloading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Base Product',
          accessor: 'part1'
        },
        {
          Header: 'Interchangeable Product',
          accessor: 'part2'
        },
        {
          Header: 'Interchangeability',
          accessor: 'int'
        },
        {
          Header: 'One Way Forward',
          accessor: 'deprecated',
          Cell: ({ cell }) => {
            return <Checkbox isDisabled isChecked={cell.value} />;
          },
        },
        {
          Header: 'Sticky Customers',
          accessor: 'sticky'
        }
      ]
    , []
  );

  console.log("isLoading: " + isLoading);
  useEffect(() => {
    if (!isLoading) {
      const sku = swrdata.sku.map(a => ({ value: a.SKU, label: a.SKU }));
      setDearData(sku);
    }
  }, [swrdata, isLoading])
  useEffect(() => {
    fetch(host + '/api/getskuqty', {
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => res.json()).then(results => {
      console.log(results);
      setQty(results);
    });
  }, []);
  useEffect(() => {
    console.log(refresh);
    fetch(host + "/api/getintproducts", {
      method: "GET",
      credentials: "include"
    }).then(res => res.json()).then(data => {
      console.log(data);
      setData(data);
      setdataloading(false);
    })
  }, [refresh])
  console.log(datastate);
  const data = useMemo(() => datastate, [datastate]);



  const IntProductSubmit = (values) => {
    console.log(values);
    const submitval = [];
    submitval.push(values);
    fetch(host + "/api/intsubmit", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submitval)
    }).then(res => {
      if (res.status === 200) {
        reset();
        onClose();
        setRefresh(refresh + 1);
        return res.text();
      } else throw new Error(res.status)
    }).then(function (text) {
      alert(text);
      /* Need to Clear Form here */
    }).catch((error) => {
      console.log(error);
    })
  }
  const schema = Yup.object({
    intsku: Yup.string().transform((curr, orig) => orig.value).required("Required"),
    basesku: Yup.string().transform((curr, orig) => orig.value).required("Required"),
    bskuqty: Yup.number().positive().required("Required"),
    iskuqty: Yup.number().positive().required("Required")
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields, isSubmitting },
    setValue,
    control,
  } = useForm({
    defaultValues: {
      bskuqty: 0,
      iskuqty: 0
    },
    resolver: yupResolver(schema)
  });
  return (
    <Box w="100%">
      {(isLoading || dataloading) ? (
        <Flex grow={1} align="center" justify="center">
          <Spinner />
          <p>Loading...</p>
        </Flex>
      ) : (
        <Flex align="center" ml={1} direction="column">
          <Flex direction="column">
            <Box>
              <Heading>Interchangeable Product Info</Heading>
            </Box>
            <Box>
              <Text>This module allows users to view, search and create interchangeable products</Text>
            </Box>
            <Box>
              <Button loadingText="Getting SKU's" onClick={onOpen} ml={2}>Add Interchangeability Record</Button>
              <CustomerReq />
            </Box>
          </Flex>
          <Divider />
          <Flex>
            <Box>
              <Text>Bulk Upload: </Text>
            </Box>
            <Box>
              <Parse />
            </Box>
          </Flex>
          <Divider />
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add New Interchangeability Record</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <form id="intproductform" onSubmit={handleSubmit(IntProductSubmit)}>
                    <Flex>
                      <FormControl isRequired isInvalid={!!errors.basesku && dirtyFields.basesku} isDisabled={isSubmitting}>
                        <FormLabel>Base SKU</FormLabel>
                        <Controller
                          control={control}
                          name="basesku"
                          render={({ field }) => (
                            <Select ref={bskuselect} useBasicStyles name={field.name} options={dearsku} isSearchable onChange={(value) => {
                              field.onChange(value);
                              const qtyvalue = qty.filter(item => item.SKU === value.value);
                              if (qtyvalue[0].Qty === null) {
                                setValue('bskuqty', 0);
                              } else {
                                setValue('bskuqty', qtyvalue[0].Qty);
                              }

                            }} />
                          )}
                        />
                        <FormErrorMessage>{errors?.basesku?.message}</FormErrorMessage>
                      </FormControl>
                      <Tooltip label="If this input box has not been auto-filled, fill in the per box quantity of this SKU. The system will calculate the ratio between the Base and Interchangeable SKU's and assign it a preference value.">
                        <FormControl ml={3} isRequired isInvalid={!!errors.bskuqty && dirtyFields.bskuqty} isDisabled={isSubmitting}>
                          <FormLabel>Quantity</FormLabel>
                          <Input {...register('bskuqty')} />
                          <FormErrorMessage>{errors?.bskuqty?.message}</FormErrorMessage>
                        </FormControl>
                      </Tooltip>
                    </Flex>
                    <Tooltip label="Check this box to mark this relationship as one way forward from base SKU to interchangeable SKU. This does not deprecate the product in DEAR.">
                      <FormControl mt={2} isDisabled={isSubmitting}>
                        <Flex >
                          <FormLabel>Deprecated</FormLabel><Checkbox {...register('deprecated')} />
                        </Flex>
                      </FormControl>
                    </Tooltip>
                    <Flex mt={5}>
                      <FormControl isRequired isInvalid={!!errors.intsku && dirtyFields.intsku} isDisabled={isSubmitting}>
                        <FormLabel>Interchangeable SKU</FormLabel>
                        <Controller
                          control={control}
                          name="intsku"
                          render={({ field }) => (
                            <Select useBasicStyles name={field.name} options={dearsku} isSearchable onChange={(value) => {
                              field.onChange(value);
                              const qtyvalue = qty.filter(item => item.SKU === value.value);
                              if (qtyvalue[0].Qty === null) {
                                setValue('iskuqty', 0);
                              } else {
                                setValue('iskuqty', qtyvalue[0].Qty);
                              }
                            }} />
                          )} />
                        <FormErrorMessage>{errors?.intsku?.message}</FormErrorMessage>
                      </FormControl>
                      <Tooltip label="If this input box has not been auto-filled, fill in the per box quantity of this SKU. The system will calculate the ratio between the Base and Interchangeable SKU's and assign it a preference value.">
                        <FormControl ml={3} isRequired isInvalid={!!errors.iskuqty && dirtyFields.iskuqty} isDisabled={isSubmitting}>
                          <FormLabel>Quantity</FormLabel>
                          <Input {...register('iskuqty')} />
                          <FormErrorMessage>{errors?.iskuqty?.message}</FormErrorMessage>
                        </FormControl>
                      </Tooltip>
                    </Flex>
                  </form>
                </Box>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button variant='ghost' form="intproductform" type='submit'>Submit</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Table data={data} columns={columns} />
        </Flex>
      )}
    </Box>
  )
}