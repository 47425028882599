import React from 'react';
import {
    ModalHeader,
    Divider,
    ModalCloseButton,
    ModalFooter,
    Text,
    Button,
    Flex,
    Heading,
    useDisclosure,
    ModalOverlay,
    ModalContent,
    Modal,
    ModalBody,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react';

export default function SaleSuccess({data}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    React.useEffect(() => {
        if(data){
            onOpen();
        }
    },[data])

    return(
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
          <ModalHeader>{data.SaleOrderNumber}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column">
            <Flex justifyContent="center">
            <Heading m={2} size="md" color="blue">Order Confirmation</Heading>
            </Flex>
            {!!data.Lines.length &&
            <TableContainer mb={2}>
                <Table>
                    <TableCaption placement="top">Products</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>SKU</Th>
                            <Th>Quantity</Th>
                            <Th>Price</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                    {data.Lines.map((line,i) => 
                        <Tr key={i}>
                            <Td>{line.SKU}</Td>
                            <Td>{line.Quantity}</Td>
                            <Td>${line.Price}</Td>
                        </Tr>
                    )}
                    </Tbody>
                </Table>
            </TableContainer>}
            {!!data.AdditionalCharges.length && 
                <TableContainer mt={2}>
                <Table>
                <TableCaption placement="top">Additional Charges</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Item</Th>
                            <Th isNumeric>Amount</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                    {data.Lines.map((line,i) => 
                        <Tr key={i}>
                            <Td>{line.description}</Td>
                            <Td>{line.Price}</Td>
                        </Tr>
                    )}
                    </Tbody>
                </Table>
            </TableContainer>
            }
            {(data.Memo && data.Memo != "undefined") &&
            <>
            <Text mt={2} as="u">Order Memo:</Text>
            <Text mt={2} p={4} bg="gray.50" borderRadius={10}>{data.Memo}</Text>
            </>}
            <Flex direction="row" justifyContent="end">
            <Text as="b" fontSize="lg" m={4}>Total: ${data.Total}</Text>
            </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
    )
}