import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
export default function GetCardDetails(data) {
  const clientSecret = data.clientSecret;
  const host = data.host;
  const onClose = data.onClose;
  const customer = data.customer;
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();


  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submissi on until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    console.log(elements);
    if (data.mode === "setup") {
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }

      const url = 'https://' + window.location.hostname;
      // Confirm the SetupIntent using the details collected by the Payment Element
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: url + '/pmtcomplete',
        },
        redirect: "if_required"
      });

      if (error) {
        // This point is only reached if there's an immediate error when
        // confirming the setup. Show the error to your customer (for example, payment details incomplete)
        handleError(error);
      } else {
        setupIntent.customer = customer;
        try {
          const response = await fetch(host + "/api/cardupdate", {
            method: 'POST',
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(setupIntent)
          })
          const message = await response.text()
          toast({
            title: response.ok ? "Customer Created" : "Customer creation failed",
            description: message,
            status: response.ok ? "success" : "error",
            duration: 9000,
            isClosable: true,
          });
        } catch (e){
          toast({
            title: "Customer creation failed",
            description: e,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
        onClose();
      }
    } else {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "https://int.vibecartons.com/orders",
        },
        redirect: "if_required"
      });

      if (error) {
        handleError(error.message);
      } else {
        onClose();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button mr={2} mt={2} onClick={() => onClose()}>{data.mode === "setup" ? "Skip" : "Cancel"}</Button>
      <Button mt={2} colorScheme="green" type="submit" disabled={!stripe || loading}>
        {loading ? 'Submitting...' : 'Submit'}
      </Button>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
}