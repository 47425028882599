import React from 'react';
import {createRoot } from 'react-dom/client';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import './index.css';
import App from './App';
import {AuthProvider} from './components/AuthContext/authContext'
import { SWRConfig } from 'swr';
import "regenerator-runtime/runtime.js";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://a7917c2309f719cf2636fa5247aaf84c@o4506067902726144.ingest.us.sentry.io/4507110306086912",
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && event.event_id) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
    }),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
    }),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({
      levels:['warn','error'],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
  <SWRConfig
  value={{
  fetcher: (url) => fetch(url,{method: "GET", credentials:"include"}).then(res => res.json())
  }}
  >
  <AuthProvider>
      <App />
  </AuthProvider>
  </SWRConfig>
  </BrowserRouter>);
