import React, {useState} from 'react';
import Papa from 'papaparse';
import { Input, useToast } from '@chakra-ui/react'
const host = import.meta.env.VITE_REACT_APP_HOST;

export default function Parse() {
  const [val,setVal] = useState([]);
    const handleChange = (e) => {
        const config = {
                header: true,
                complete: function(results, file){
                  const newval = val;
                  newval.push(results);
                  setVal(newval);
                },
                skipEmptyLines: true,
                delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
        }
        if (e.target.files && e.target.files[0]){
            for (let i=0; i<e.target.files.length; i++){
                const file = e.target.files[i];
                Papa.parse(file,config); 
        }
    };
    SubmitFile(val);
  }
        return(
            <Input type="file" onChange={handleChange}/>
        )
}

function SubmitFile (results) {
  const toast = useToast()
  console.log(results);
  fetch(host + "/api/intsubmit",{
    method:"POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(results)
  }).then(res => {
          if(res.status === 200) {
            toast({
              title: 'Upload Successful',
              description: "Please refresh the page",
              status: 'success',
              duration: 9000,
              isClosable: true,
            })
          } else throw new Error(res.status)
    }).catch((error) => {
      console.log(error);
    })
}