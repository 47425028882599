import {useState, useEffect, useMemo} from "react";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Heading,
    Text,
    Button,
    Spacer,
    Flex,
    Center,
    useToast,
} from '@chakra-ui/react';
import useSWR from 'swr';
import Loading from "./components/loading";
const host = import.meta.env.VITE_REACT_APP_HOST;

export default function ReturnManager(){
    const toast=useToast();
    const [isSubmitting, setSubmitting] = useState(false);
    const {data:returns, isLoading, mutate} = useSWR(host + "/api/getreturnlist");
    const submitItem = async(i) => {
        setSubmitting(true);
        await fetch(host + "/api/restock", {
            method:"POST",
            credentials:"include",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify(returns[i])
        }).then(async(res) => {
            if(res.ok){
                try{
                    //mutate(returns.filter((el, a) => a !== i));
                    //Optimistic reloads are giving issues. Refresh the page instead to isolate issues. 
                    mutate();
                    setSubmitting(false);
                    toast({
                        title: 'Success',
                        description: 'Return Confirmed',
                        status:'success',
                        duration:4500,
                        isClosable:true,
                      })
                      console.log(res);
                    //setTimeout(() => (window.location.reload()), 2000);   
                } catch (e) {
                    toast({
                        title: 'Error',
                        description: e,
                        status:'error',
                        duration:4500,
                        isClosable:true,
                      })    
                }
            } else {
                console.log(res);
                setSubmitting(false);
                toast({
                    title: 'Error',
                    description: res.status,
                    status:'error',
                    duration:4500,
                    isClosable:true,
                  }) 
            }
        });
    }
    console.log(returns);
    return(
        <>
        {isLoading ? <Loading/> : (
        <>
        {(returns && returns.length) ? (
            <Accordion allowToggle>
            {returns.map((item,i) => 
             <AccordionItem>
                <Heading>
                <AccordionButton>
                    <Flex direction="row" w="100%">
                        <Heading size="xs">{item.Customer[0].Name}</Heading>
                        <Spacer />
                        <Text>{item.InvoiceNumber}</Text>
                        <Spacer />
                    </Flex>
                    <AccordionIcon />
                </AccordionButton>
                </Heading>
                <AccordionPanel pb={4} w="100%">
                <Flex direction="row" w="100%">
                    <Flex direction="column">
                    <Text>SKU and Return Quantity</Text>
                    {item.RestockData.map((restock,i) => {
                        if(!restock) {
                            return <Text>No return items</Text>
                        } else {
                            return <Text key={i}>{restock.SKU}:         {restock.Quantity}</Text>
                        }
                    }
                    )}
                    </Flex>
                    <Spacer />
                <Button isLoading={isSubmitting} onClick={()=>submitItem(i)}>Confirm Receipt</Button>
                </Flex>
                </AccordionPanel>
             </AccordionItem>   
            )}
        </Accordion>
        ):(
            <Center>
                <Text>No Data Yet</Text>
            </Center>
        )
        }
        </>        
        )}
        </>
        )
}