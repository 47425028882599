import { useState, useEffect } from 'react';
import {
    useDisclosure,
    Tooltip,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    NumberInput,
    NumberInputField,
    Divider,
    useToast,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { useForm, Controller,useFieldArray } from "react-hook-form";
import useSKU from './components/fetchsku.js';
import { useSWRConfig } from "swr"


const styles = {
    control: (css) => ({
      ...css,
      width: 150
    }),
    menu: ({ width, ...css }) => ({
      ...css,
      width: "max-content",
      minWidth: "20%"
    }),
    // Add padding to account for width of Indicators Container plus padding
    option: (css) => ({ ...css, width: 500 })
  };

const host = import.meta.env.VITE_REACT_APP_HOST;


export default function CreateProductDiscount() {
    const { mutate } = useSWRConfig()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [dearsku, setDearData] = useState();


    let dvalue = {
        customer:"",
        products:[{sku:"", qty:"1", customprice:""}]
    };

    const schema=Yup.object({
        customer: Yup.string().required("Required").transform((curr,orig) => orig.value),
        products: Yup.array(
          Yup.object({
            sku:Yup.string().required("Required").transform((curr,orig) => orig.value),
            qty:Yup.number().required("Required"),
            customprice:Yup.number().nullable().transform((curr, orig) => (orig === "" ? null : curr)).positive("Must be a positive value")
          })
        ),
      });

    const {
        handleSubmit,
        register,
        control,
        formState: { errors, isSubmitting,dirtyFields },
    } = useForm({
        defaultValues:dvalue,
        resolver: yupResolver(schema)
    });

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "products", // unique name for your Field Array
    });

    const { swrdata, isLoading } = useSKU();
    const [loadOptions, setCustomers] = useState();
    const toast = useToast();
    useEffect(() => {
        if(!isLoading){
            setCustomers(swrdata.customers.map(o => ({ value: o.Name, label: o.Name })));
            setDearData(swrdata.allproducts.map(a=>({value:a.SKU,label:a.SKU + ":" + a.Name})));
        }
    }, [swrdata, isLoading])



    const onSubmit = async (data) => {
        await fetch(host + "/api/customprice", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.ok) {
                onClose();
                toast({
                    title: "Success",
                    description: "Changes Submitted",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
                mutate();
            }
        })
    }


    return (
        <>
            <Button isLoading={isLoading} m={2} onClick={() => onOpen()}>Product Level Discounts</Button>
            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Product Discounts</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>This form creates discounted product prices for customers.</Text>
                        <form id="productdiscount" onSubmit={handleSubmit(onSubmit)}>
                            <FormControl isInvalid={errors.customer}>
                                <FormLabel htmlFor='customer'>Select Customer:</FormLabel>
                                <Controller
                                    name="customer"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                {...field}
                                                isClearable
                                                value={field.value}
                                                options={loadOptions}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                            />)
                                    }}
                                />
                            </FormControl>
                            <Divider />
                            {fields.map((product, index) => (
                    <Wrap key={product.id} justify="center" align="center">
                      <WrapItem w={[,100,,350]}>
                        <FormControl isInvalid={!!errors?.products?.[index]?.sku && dirtyFields?.products?.[index]?.sku} isDisabled={isSubmitting}>
                          <FormLabel>SKU:</FormLabel>
                          <Controller
                          control={control}
                          name={`products.${index}.sku`}
                          render={({field}) => 
                          <Select value={field.value} styles={styles} name={field.name} options={dearsku} isSearchable onChange={field.onChange} />
                        }/>
                        <FormErrorMessage>{errors?.products?.[index]?.sku?.message}</FormErrorMessage>
                        </FormControl>
                      </WrapItem>
                      <WrapItem w={[75,,,,150]}>
                      <Tooltip label="Minimum quantity in order required for discount to activate. Defaults to 1">
                        <FormControl isDisabled={isSubmitting}>
                          <FormLabel>Qty</FormLabel>
                          <NumberInput pl={2} isRequired>
                            <NumberInputField {...register(`products.${index}.qty`)}/>
                          </NumberInput>
                        </FormControl>
                        </Tooltip>
                        <FormErrorMessage>{errors?.products?.[index]?.qty?.message}</FormErrorMessage>
                      </WrapItem>
                      <WrapItem w={140}>
                        <FormControl isDisabled={isSubmitting}>
                          <FormLabel>Custom Price</FormLabel>
                          <NumberInput pl={2}>
                            <NumberInputField {...register(`products.${index}.customprice`)} />
                            </NumberInput>
                        </FormControl>
                        <FormErrorMessage>{errors?.products?.[index]?.customprice?.message}</FormErrorMessage>
                      </WrapItem>
                      <WrapItem direction="row" align="end" pt={8}>
                        <Button ml={2} hideBelow='md' colorScheme='red' variant='solid' size='md'
                          onClick={() => remove(index)}
                        >Delete Row</Button>
                      </WrapItem>
                      <WrapItem direction="row" align="end" pt={8}>
                        <Button ml={2} hideFrom='md' colorScheme='red' variant='solid' size='md'
                          onClick={() => remove(index)}
                        >X</Button>
                      </WrapItem>
                    </Wrap>
                  ))}
                      <Button size='sm' colorScheme='green'
                          onClick={() => append({ sku: '', qty: '1' , customprice:''})}
                      >
                      Add Row
                      </Button>
                        </form> 
                    </ModalBody>
                    <ModalFooter>
                    <Button form="productdiscount" type="submit" colorScheme="blue">Submit</Button>    
                    </ModalFooter>
                </ModalContent>

            </Modal>
        </>
    )
}