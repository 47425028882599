import React, { useState, useEffect } from "react";
import {
    Flex,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    useDisclosure,
    Skeleton,
    Text,
} from '@chakra-ui/react'
import { useAuthState } from './components/AuthContext/authContext';
import { useNavigate } from "react-router-dom";
import { ContainerDetailsModal, InvoiceModal, NewContainerModal, ReceiveModal, ShippingFeeModal } from "./components/modals";

export default function Purchases() {
    const [selectedcontainer, setSelectedContainer] = useState({});
    const [containers, setContainers] = useState();
    const [isLoading, setLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isInvoiceOpen, onOpen: onInvoiceOpen, onClose: onInvoiceClose } = useDisclosure();
    const { isOpen: isReceiveOpen, onOpen: onReceiveOpen, onClose: onReceiveClose } = useDisclosure();
    const { isOpen: isFeeOpen, onOpen: onFeeOpen, onClose: onFeeClose } = useDisclosure();
    const { user } = useAuthState();
    const navigate = useNavigate();
    
    const associateInvoice = (container) => {
        setSelectedContainer(container);
        onInvoiceOpen();
    }

    const handleClick = (container) => {
        setSelectedContainer(container);
        onOpen();
    }
    const StartReceive = (container) => {
        setSelectedContainer(container);
        onReceiveOpen();
    }
    const AddFees = (container) => {
        setSelectedContainer(container);
        onFeeOpen();
    }

    useEffect(() => {
        fetch(__host__ + "/api/purchases", {
            method: "GET",
            credentials: "include"
        })
            .then(res => res.json())
            .then(data => { console.log(data); setContainers(data); setLoading(false) });
    }, [])

    console.log(containers);
    return (
        <Flex direction="column">
            <NewContainerModal />
            <ContainerDetailsModal associateInvoice={associateInvoice} StartReceive={StartReceive} selectedcontainer={selectedcontainer} isOpen={isOpen} onClose={onClose} />
            <InvoiceModal selectedcontainer={selectedcontainer} isInvoiceOpen={isInvoiceOpen} onInvoiceClose={onInvoiceClose} />
            <ReceiveModal selectedcontainer={selectedcontainer} isOpen={isReceiveOpen} onClose={onReceiveClose} />
            <ShippingFeeModal selectedcontainer={selectedcontainer} isOpen={isFeeOpen} onClose={onFeeClose} />
            <Skeleton isLoaded={!isLoading}>
                {!!containers?.length ? containers?.map((container,i) =>
                    <Card key={i} mt={4}>
                        <CardHeader>{container.Name}</CardHeader>
                        <CardBody>
                            <table>
                                <tr>
                                    <th>Container Number:</th>
                                    <td>{container.ContainerNumber}</td>
                                </tr>
                                <tr>
                                    <th>Number of Items:</th>
                                    <td>{container.TotalQuantity}</td>
                                </tr>
                                {user.permissions.seeVendors &&
                                    <tr>
                                        <th>Vendors:</th>
                                        <td>{container.Vendors.toString()}</td>
                                    </tr>
                                }
                                <tr>
                                    <th>SKUs:</th>
                                    <td>{container.SKUs.toString()}</td>
                                </tr>
                                <tr>
                                    <th>Status:</th>
                                    <td>{container.Status}</td>
                                </tr>
                            </table>
                        </CardBody>
                        <CardFooter>
                            <Button m={2} onClick={() => associateInvoice(container)}>Save Vendor Invoice</Button>
                            <Button m={2} onClick={() => handleClick(container)}>View Details</Button>
                            <Button m={2} onClick={() => AddFees(container)}>Add Shipping Fees</Button>
                            <Button m={2} onClick={() => StartReceive(container)}>Start Receive Process</Button>
                        </CardFooter>
                    </Card>
                ) :
                    <Card mt={8}>
                        <CardHeader>Current Containers</CardHeader>
                        <CardBody>
                            <Text>No Data Here</Text>
                        </CardBody>
                    </Card>}
            </Skeleton>
        </Flex>
    )
}