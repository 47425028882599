import React, { useState, useEffect } from "react";
import {
    Flex,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Checkbox,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Skeleton,
    useToast,
    FormControl,
    FormLabel,
    Input,
    Divider,
    Wrap,
    WrapItem,
    FormErrorMessage,
    NumberInput,
    NumberInputField,
    Text,
    Tooltip
} from '@chakra-ui/react'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import useSWR from "swr";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import useSKU from "./fetchsku";
import { Select } from 'chakra-react-select';
import { fetcher } from "./fetchsku";



//New Container
export function NewContainerModal() {
    const { isOpen: createOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
    const [dearsku, setDearData] = useState();
    const { data: dearvendors, isLoading: isVendorLoading } = useSWR(__host__ + "/api/getvendors", fetcher);
    const { swrdata, isLoading: isSKULoading } = useSKU();
    const toast = useToast();
    useEffect(() => {
        if (!isSKULoading) {
            setDearData(swrdata.allproducts.map(a => ({ value: a.SKU, label: a.SKU + ":" + a.Name })));
        }
    }, [swrdata, isSKULoading])

    let dvalue = {
        orderdata: [{ sku: "", qty: "1", vendor: "" }]
    };
    const schema = Yup.object({
        Name: Yup.string().required("Required"),
        orderdata: Yup.array(
            Yup.object({
                sku: Yup.string().required("Required").transform((curr, orig) => orig.value),
                qty: Yup.number().required("Required"),
                vendor: Yup.string().required("Required").transform((curr, orig) => orig.label)
            })
        ),
    });
    const {
        handleSubmit,
        register,
        control,
        formState: { errors, isSubmitting, dirtyFields },
    } = useForm({
        defaultValues: dvalue,
        resolver: yupResolver(schema)
    });

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "orderdata", // unique name for your Field Array
    });
    const onSubmit = async (data) => {
        console.log(data);
        const vendors = data.orderdata.reduce((acc, curr) => {
            if (acc.includes(curr.vendor)) {
                return acc
            } else {
                const temp = acc;
                temp.push(curr.vendor);
                return temp;
            }
        }, []);
        const skus = data.orderdata.reduce((acc, curr) => {
            if (acc.includes(curr.sku)) {
                return acc
            } else {
                const temp = acc;
                temp.push(curr.sku);
                return temp;
            }
        }, [])
        data.Vendors = vendors;
        data.SKUs = skus;
        await fetch(__host__ + "/api/purchases", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.ok) {
                onCreateClose();
                toast({
                    title: "Success",
                    description: "Order Submitted",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: "Failed",
                    description: "Error",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
        })
    }
    return (
        <>
            <Button onClick={onCreateOpen}>Create New Container Purchase</Button>
            <Modal isOpen={createOpen} onClose={onCreateClose} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Container</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>This form creates a new container purchase. Do not use this for pallet purchases.</Text>
                        <form id="createpurchase" onSubmit={handleSubmit(onSubmit)}>
                            <FormControl isInvalid={errors.Name}>
                                <FormLabel htmlFor='Name'>Enter Name of Purchase Order:</FormLabel>
                                <Input placeholder="74th Container" {...register("Name")} />
                            </FormControl>
                            <FormControl isInvalid={errors.ContainerNumber}>
                                <FormLabel htmlFor="ContainerNumber">Container Number received from Carrier</FormLabel>
                                <Input placeholder="optional" {...register("ContainerNumber")} />
                            </FormControl>
                            <Divider />
                            {fields.map((orderdata, index) => (
                                <Wrap key={orderdata.id} justify="center" align="center">
                                    <WrapItem w={[, 100, , 350]}>
                                        <FormControl isInvalid={!!errors?.orderdata?.[index]?.sku && dirtyFields?.orderdata?.[index]?.sku} isDisabled={isSubmitting}>
                                            <FormLabel>SKU:</FormLabel>
                                            <Controller
                                                control={control}
                                                name={`orderdata.${index}.sku`}
                                                render={({ field }) =>
                                                    <Select value={field.value} name={field.name} options={dearsku} autoFocus blurInputOnSelect captureMenuScroll isSearchable onChange={field.onChange} />
                                                } />
                                            <FormErrorMessage>{errors?.orderdata?.[index]?.sku?.message}</FormErrorMessage>
                                        </FormControl>
                                    </WrapItem>
                                    <WrapItem w={[75, , , , 150]}>
                                        <FormControl isDisabled={isSubmitting}>
                                            <FormLabel>Quantity</FormLabel>
                                            <NumberInput pl={2} isRequired>
                                                <NumberInputField {...register(`orderdata.${index}.qty`)} />
                                            </NumberInput>
                                        </FormControl>
                                        <FormErrorMessage>{errors?.orderdata?.[index]?.qty?.message}</FormErrorMessage>
                                    </WrapItem>
                                    <WrapItem hideBelow='md' w={140}>
                                        <FormControl isDisabled={isSubmitting}>
                                            <FormLabel>Vendor</FormLabel>
                                            <Controller
                                                control={control}
                                                name={`orderdata.${index}.vendor`}
                                                render={({ field }) =>
                                                    <Skeleton isLoaded={!isVendorLoading}>
                                                    <Select value={field.value} name={field.name} options={dearvendors} isSearchable onChange={field.onChange} />
                                                    </Skeleton>
                                                } />
                                        </FormControl>
                                        <FormErrorMessage>{errors?.orderdata?.[index]?.vendor?.message}</FormErrorMessage>
                                    </WrapItem>
                                    <WrapItem direction="row" align="end" pt={8}>
                                        <Button ml={2} hideBelow='md' colorScheme='red' variant='solid' size='md'
                                            onClick={() => remove(index)}
                                        >Delete Row</Button>
                                    </WrapItem>
                                    <WrapItem direction="row" align="end" pt={8}>
                                        <Button ml={2} hideFrom='md' colorScheme='red' variant='solid' size='md'
                                            onClick={() => remove(index)}
                                        >X</Button>
                                    </WrapItem>
                                </Wrap>
                            ))}
                            <Button size='sm' colorScheme='green'
                                onClick={() => append({ sku: '', qty: '1', vendor: '' })}
                            >
                                Add Row
                            </Button>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button form="createpurchase" type="submit" colorScheme="blue">Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

//Container Details
export function ContainerDetailsModal({ associateInvoice, StartReceive, selectedcontainer, isOpen, onClose }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{selectedcontainer.Name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <table>
                        <tr>
                            <th>Container Number:</th>
                            <td>{selectedcontainer.ContainerNumber}</td>
                        </tr>
                        <tr>
                            <th>Number of Items:</th>
                            <td>{selectedcontainer.TotalQuantity}</td>
                        </tr>
                        <tr>
                            <th>Status:</th>
                            <td>{selectedcontainer.Status}</td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <th>SKU</th>
                            <th>Quantity</th>
                            <th>Vendor</th>
                        </tr>
                        {selectedcontainer.orderdata?.map((skurow, i) =>
                            <tr key={i}>
                                <td>{skurow.sku}</td>
                                <td>{skurow.qty}</td>
                                <td>{skurow.vendor}</td>
                            </tr>
                        )}
                    </table>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button m={2} p={2} onClick={() => associateInvoice(selectedcontainer)}>Input Invoice</Button>
                    <Button m={2} p={2} onClick={() => StartReceive(selectedcontainer)}>Start Receive </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

//Invoice Modal
export function InvoiceModal({ selectedcontainer, isInvoiceOpen, onInvoiceClose }) {
    const [dearsku, setDearData] = useState();
    const [serviceskus, setDearServiceSkus] = useState();
    const { swrdata, isLoading: isSKULoading } = useSKU();
    const toast = useToast();
    useEffect(() => {
        if (!isSKULoading) {
            setDearData(swrdata.allproducts.map(a => ({ value: a.SKU, label: a.SKU + ":" + a.Name })));
            setDearServiceSkus(swrdata.servicesku.map(a=>({value:a.Name,label:a.Name})));    
        }
    }, [swrdata, isSKULoading])

    console.log(selectedcontainer)
    const dateformat = 'MM/DD';
    const [addchargedisplay, setACDisplay] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, dirtyFields, isSubmitting },
        reset,
        control,
    } = useForm({
        defaultValues: {
            addchargedisplay: false,
        },
        //resolver: yupResolver(schema),
    });
    const { fields, append, remove, replace } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "orderdata", // unique name for your Field Array
    });

    const { fields: acfields, append: acappend, remove: acremove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "addcharge", // unique name for your Field Array
    });
    const containervendors = selectedcontainer?.Vendors?.map(vendor => {
        return {
            label: vendor,
            value: vendor
        }
    });
    const onSubmit = async (data) => {
        data.id = selectedcontainer.id
        console.log(data);
        await fetch(__host__ + "/api/purchaseinvoice", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.ok) {
                onInvoiceClose();
                toast({
                    title: "Success",
                    description: "Invoice Submitted",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: "Failed",
                    description: "Error",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
        })
    }
    return (
        <Modal isOpen={isInvoiceOpen} onClose={onInvoiceClose} size="4xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Vendor Invoice</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>This form creates a vendor invoice for this container</Text>
                    <form id="createinvoice" onSubmit={handleSubmit(onSubmit)}>
                        <FormControl isInvalid={!!errors.vendor && dirtyFields.vendor} isDisabled={isSubmitting}>
                            <FormLabel>Vendor:</FormLabel>
                            <Controller
                                control={control}
                                name={`vendor`}
                                render={({ field }) =>
                                    <Select value={field.value} name={field.name} options={containervendors} autoFocus blurInputOnSelect captureMenuScroll isSearchable onChange={(vendor) => {
                                        field.onChange(vendor);
                                        replace(selectedcontainer.PurchaseOrders.find(order => order.Vendor === vendor.value).PurchaseOrderData.Lines.map(line => {
                                            const selsku = dearsku.find(dsku => dsku.value === line.SKU);
                                            console.log(selsku);
                                            return {
                                                sku: selsku,
                                                qty: line.Quantity,
                                                price: line.Price
                                            }
                                        }))
                                    }} />
                                } />
                            <FormErrorMessage>{errors?.vendor?.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors.date} isDisabled={isSubmitting}>
                            <FormLabel>Invoice Date:</FormLabel>
                            <Input
                                type="date"
                                format={dateformat}
                                {...register('InvoiceDate')}
                            />
                            <FormErrorMessage>{errors?.InvoiceDate?.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!errors.duedate} isDisabled={isSubmitting}>
                            <FormLabel>Invoice Due Date:</FormLabel>
                            <Input
                                type="date"
                                format={dateformat}
                                {...register('InvoiceDueDate')}
                            />
                            <FormErrorMessage>{errors?.InvoiceDueDate?.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.InvoiceNumber}>
                            <FormLabel htmlFor="InvoiceNumber">Invoice Number</FormLabel>
                            <Input {...register("InvoiceNumber")} />
                        </FormControl>
                        <Divider />
                        {fields.map((orderdata, index) => (
                            <Wrap key={orderdata.id} justify="center" align="center">
                                <WrapItem w={[, 100, , 350]}>
                                    <FormControl isInvalid={!!errors?.orderdata?.[index]?.sku && dirtyFields?.orderdata?.[index]?.sku} isDisabled={isSubmitting}>
                                        <FormLabel>SKU:</FormLabel>
                                        <Controller
                                            control={control}
                                            name={`orderdata.${index}.sku`}
                                            render={({ field }) =>
                                                <Select value={field.value} name={field.name} options={dearsku} autoFocus blurInputOnSelect captureMenuScroll isSearchable onChange={field.onChange} />
                                            } />
                                        <FormErrorMessage>{errors?.orderdata?.[index]?.sku?.message}</FormErrorMessage>
                                    </FormControl>
                                </WrapItem>
                                <WrapItem w={[75, , , , 150]}>
                                    <FormControl isDisabled={isSubmitting}>
                                        <FormLabel>Quantity</FormLabel>
                                        <NumberInput pl={2} isRequired>
                                            <NumberInputField {...register(`orderdata.${index}.qty`)} />
                                        </NumberInput>
                                    </FormControl>
                                    <FormErrorMessage>{errors?.orderdata?.[index]?.qty?.message}</FormErrorMessage>
                                </WrapItem>
                                <WrapItem w={[75, , , , 150]}>
                                    <FormControl isDisabled={isSubmitting}>
                                        <FormLabel>Price</FormLabel>
                                        <NumberInput pl={2} isRequired>
                                            <NumberInputField {...register(`orderdata.${index}.price`)} />
                                        </NumberInput>
                                    </FormControl>
                                    <FormErrorMessage>{errors?.orderdata?.[index]?.price?.message}</FormErrorMessage>
                                </WrapItem>
                                <WrapItem direction="row" align="end" pt={8}>
                                    <Button ml={2} hideBelow='md' colorScheme='red' variant='solid' size='md'
                                        onClick={() => remove(index)}
                                    >Delete Row</Button>
                                </WrapItem>
                                <WrapItem direction="row" align="end" pt={8}>
                                    <Button ml={2} hideFrom='md' colorScheme='red' variant='solid' size='md'
                                        onClick={() => remove(index)}
                                    >X</Button>
                                </WrapItem>
                            </Wrap>
                        ))}
                        <Button size='sm' colorScheme='green'
                            onClick={() => append({ sku: '', qty: '1', vendor: '' })}
                        >
                            Add Row
                        </Button>
                        <Divider />
                        <Button type="button" colorScheme="blue" onClick={() => {
                            setACDisplay(!addchargedisplay);
                            addchargedisplay ? acremove() : acappend({ item: '', price: '' });
                        }}>{addchargedisplay ? "Remove Additional Charges" : "Add Additional Charges"}</Button>
                        <Flex display={addchargedisplay ? "flex" : "none"}>
                            {acfields.map((acharge, index) => (
                                <Wrap key={acharge.id} justify="center" align="center">
                                    <WrapItem w={[, 250, , 550]}>
                                        <FormControl isInvalid={!!errors?.addcharge?.[index]?.item && dirtyFields?.addcharge?.[index]?.item} isDisabled={isSubmitting || continuesubmit}>
                                            <FormLabel>Item:</FormLabel>
                                            <Controller
                                                control={control}
                                                name={`addcharge.${index}.item`}
                                                render={({ field }) =>
                                                    <Select value={field.value} styles={styles} name={field.name} options={serviceskus} isSearchable onChange={field.onChange} />
                                                } />
                                            <FormErrorMessage>{errors?.addcharge?.[index]?.item?.message}</FormErrorMessage>
                                        </FormControl>
                                    </WrapItem>
                                    <WrapItem w={[75, , , , 150]}>
                                        <FormControl isDisabled={isSubmitting || continuesubmit} isInvalid={!!errors?.addcharge?.[index]?.price && dirtyFields?.addcharge?.[index]?.price}>
                                            <FormLabel>Price</FormLabel>
                                            <Input pattern="(-)?[0-9]*(.[0-9]+)?" pl={2} {...register(`addcharge.${index}.price`)} />
                                        </FormControl>
                                        <FormErrorMessage>{errors?.addcharge?.[index]?.price?.message}</FormErrorMessage>
                                    </WrapItem>
                                    <WrapItem direction="row" align="end" pt={8}>
                                        <Button ml={2} display={{ base: "none", md: "flex" }} colorScheme='red' variant='solid' size='md'
                                            onClick={() => acremove(index)}
                                        >Delete Row</Button>
                                    </WrapItem>
                                    <WrapItem direction="row" align="end" pt={8}>
                                        <Button ml={2} display={{ base: "flex", md: "none" }} colorScheme='red' variant='solid' size='md'
                                            onClick={() => acremove(index)}
                                        >X</Button>
                                    </WrapItem>
                                </Wrap>
                            ))}
                            <Button size='sm' colorScheme='green'
                                onClick={() => acappend({ item: '', price: '' })}
                            >
                                Add Row
                            </Button>
                        </Flex>
                        <Checkbox {...register("addchargedisplay")} display="none" isChecked={addchargedisplay} />
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button form="createinvoice" type="submit" colorScheme="blue">Submit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export function ReceiveModal({ selectedcontainer, isOpen, onClose }) {
    const [dearsku, setDearData] = useState();
    const { swrdata, isLoading: isSKULoading } = useSKU();
    const toast = useToast();
    useEffect(() => {
        if (!isSKULoading) {
            setDearData(swrdata.allproducts.map(a => ({ value: a.SKU, label: a.SKU + ":" + a.Name })));
        }
    }, [swrdata, isSKULoading])
    console.log(selectedcontainer)
    const dateformat = 'MM/DD';
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, dirtyFields, isSubmitting },
        reset,
        control,
    } = useForm({
        defaultValues: {
            addchargedisplay: false,
        },
        //resolver: yupResolver(schema),
    });
    const { fields, append, remove, replace } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "orderdata", // unique name for your Field Array
    });

    const onSubmit = async (data) => {
        data.id = selectedcontainer.id;
        console.log(data);
        await fetch(__host__ + "/api/purchasereceive", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.ok) {
                onClose();
                toast({
                    title: "Success",
                    description: "Receive Submitted",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: "Failed",
                    description: "Error",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
        })
    }

    useEffect(() => {
        replace(selectedcontainer?.SKUs?.map(onesku => {
            const selsku = dearsku?.find(dsku => dsku.value === onesku);
            return {
                sku: selsku,
                qty: ""
            }
        }))
    }, [selectedcontainer])

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Receive Container</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>This form creates a receive for this container</Text>
                        <form id="createreceive" onSubmit={handleSubmit(onSubmit)}>
                            <FormControl isRequired isInvalid={!!errors.date} isDisabled={isSubmitting}>
                                <FormLabel>Receive Date:</FormLabel>
                                <Input
                                    type="date"
                                    format={dateformat}
                                    {...register('ReceiveDate')}
                                />
                                <FormErrorMessage>{errors?.ReceiveDate?.message}</FormErrorMessage>
                            </FormControl>
                            <Divider />
                            {fields.map((orderdata, index) => (
                                <Wrap key={orderdata.id} justify="center" align="center">
                                    <WrapItem w={[, 100, , 350]}>
                                        <FormControl isInvalid={!!errors?.orderdata?.[index]?.sku && dirtyFields?.orderdata?.[index]?.sku} isDisabled={isSubmitting}>
                                            <FormLabel>SKU:</FormLabel>
                                            <Controller
                                                control={control}
                                                name={`orderdata.${index}.sku`}
                                                render={({ field }) =>
                                                    <Select value={field.value} name={field.name} options={dearsku} autoFocus blurInputOnSelect captureMenuScroll isSearchable onChange={field.onChange} />
                                                } />
                                            <FormErrorMessage>{errors?.orderdata?.[index]?.sku?.message}</FormErrorMessage>
                                        </FormControl>
                                    </WrapItem>
                                    <WrapItem w={[75, , , , 150]}>
                                        <FormControl isDisabled={isSubmitting}>
                                            <FormLabel>Quantity</FormLabel>
                                            <NumberInput pl={2} isRequired>
                                                <NumberInputField {...register(`orderdata.${index}.qty`)} />
                                            </NumberInput>
                                        </FormControl>
                                        <FormErrorMessage>{errors?.orderdata?.[index]?.qty?.message}</FormErrorMessage>
                                    </WrapItem>
                                    <WrapItem direction="row" align="end" pt={8}>
                                        <Button ml={2} hideBelow='md' colorScheme='red' variant='solid' size='md'
                                            onClick={() => remove(index)}
                                        >Delete Row</Button>
                                    </WrapItem>
                                    <WrapItem direction="row" align="end" pt={8}>
                                        <Button ml={2} hideFrom='md' colorScheme='red' variant='solid' size='md'
                                            onClick={() => remove(index)}
                                        >X</Button>
                                    </WrapItem>
                                </Wrap>
                            ))}
                            <Button size='sm' colorScheme='green'
                                onClick={() => append({ sku: '', qty: '1', vendor: '' })}
                            >
                                Add Row
                            </Button>
                            <Divider />
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button form="createreceive" type="submit" colorScheme="blue">Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export function ShippingFeeModal({ selectedcontainer, isOpen, onClose }) {
    const [serviceskus, setDearServiceSkus] = useState();
    const { swrdata, isLoading: isSKULoading } = useSKU();
    useEffect(() => {
        if (!isSKULoading) {
            setDearServiceSkus(swrdata.servicesku.map(a=>({value:a.Name,label:a.Name})));    
        }
    }, [swrdata, isSKULoading])
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, dirtyFields, isSubmitting },
        reset,
        control,
    } = useForm({
        //resolver: yupResolver(schema),
    });
    const { fields: acfields, append: acappend, remove: acremove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "addcharge", // unique name for your Field Array
    });
    const containervendors = selectedcontainer?.Vendors?.map(vendor => {
        return {
            label: vendor,
            value: vendor
        }
    });
    const onSubmit = async (data) => {
        data.id = selectedcontainer.id;
        console.log(data);
        await fetch(__host__ + "/api/purchaseshippingfee", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.ok) {
                onInvoiceClose();
                toast({
                    title: "Success",
                    description: "Order Submitted",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: "Failed",
                    description: "Error",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
        })
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Shipping Fees</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Shipping Fees are apportioned to each vendor invoice by quantity.</Text>
                    <form id="shippingfees" onSubmit={handleSubmit(onSubmit)}>
                        <Flex direction="column">
                            {acfields.map((acharge, index) => (
                                <Wrap key={acharge.id} justify="center" align="center">
                                    <WrapItem>
                                        <FormControl isInvalid={!!errors?.addcharge?.[index]?.item && dirtyFields?.addcharge?.[index]?.item} isDisabled={isSubmitting}>
                                            <FormLabel>Item:</FormLabel>
                                            <Select {...register(`addcharge.${index}.item`)} isClearable backspaceRemovesValue options={serviceskus} />
                                            <FormErrorMessage>{errors?.addcharge?.[index]?.item?.message}</FormErrorMessage>
                                        </FormControl>
                                    </WrapItem>
                                    <WrapItem>
                                        <FormControl isDisabled={isSubmitting} isInvalid={!!errors?.addcharge?.[index]?.price && dirtyFields?.addcharge?.[index]?.price}>
                                            <FormLabel>Cost</FormLabel>
                                            <Input pattern="(-)?[0-9]*(.[0-9]+)?" pl={2} {...register(`addcharge.${index}.price`)} />
                                        </FormControl>
                                        <FormErrorMessage>{errors?.addcharge?.[index]?.price?.message}</FormErrorMessage>
                                    </WrapItem>
                                    <WrapItem>
                                    <Tooltip label="Only select a vendor if this charge is applicable solely to this vendor i.e. Anti-Dumping Duty for Kraft Items. When left blank, the charges will be apportioned between vendors by Quantity">
                                        <FormControl isInvalid={!!errors?.addcharge?.[index]?.vendor && dirtyFields?.addcharge?.[index]?.vendor} isDisabled={isSubmitting}>
                                            <FormLabel>Vendor:</FormLabel>
                                            <Select {...register(`addcharge.${index}.vendor`)} isClearable backspaceRemovesValue options={containervendors} />
                                            <FormErrorMessage>{errors?.addcharge?.[index]?.vendor?.message}</FormErrorMessage>
                                        </FormControl>
                                    </Tooltip>
                                    </WrapItem>
                                    <WrapItem direction="row" align="end" pt={8}>
                                        <Button ml={2} display={{ base: "none", md: "flex" }} colorScheme='red' variant='solid' size='md'
                                            onClick={() => acremove(index)}
                                        >Delete Row</Button>
                                    </WrapItem>
                                    <WrapItem direction="row" align="end" pt={8}>
                                        <Button ml={2} display={{ base: "flex", md: "none" }} colorScheme='red' variant='solid' size='md'
                                            onClick={() => acremove(index)}
                                        >X</Button>
                                    </WrapItem>
                                </Wrap>
                            ))}
                            <Button size='sm' colorScheme='green'
                                onClick={() => acappend({ item: '', price: '' })}
                            >
                                Add Row
                            </Button>
                        </Flex>

                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button form="shippingfee" type="submit" colorScheme="blue">Submit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

    )
}