import './App.css';
import * as React from 'react';
import {useAuthState} from './components/AuthContext/authContext'
import FullPageSpinner from './components/lib'
import AuthenticatedApp from './authenticated-app'
import { ChakraProvider, Flex } from '@chakra-ui/react'
const Login = React.lazy(() => import('./components/Login/login'))
function App() {
  const user = useAuthState().user;
  console.log(user);
    return (
      <ChakraProvider>
      <Flex minH="100vh">
      <React.Suspense fallback={<FullPageSpinner />}>
        {user ? <AuthenticatedApp /> : <Login />}
      </React.Suspense>
      </Flex>
      </ChakraProvider>
    )  }
export default App;
