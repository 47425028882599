import React, { useEffect, useState, Suspense } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useSKU from "./components/fetchsku";
import dayjs from "dayjs";
import { NSTable as Table } from "./components/table";
import { Select } from "chakra-react-select";
import { Submitting } from "./components/loading";
import {
  Checkbox,
  Spinner,
  Textarea,
  ModalHeader,
  Divider,
  ModalCloseButton,
  ModalFooter,
  Text,
  Wrap,
  NumberInput,
  NumberInputField,
  Card,
  CardBody,
  Input,
  Button,
  Box,
  Flex,
  CardHeader,
  CardFooter,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  Modal,
  ModalBody,
  useToast,
  AbsoluteCenter,
  WrapItem,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import SaleSuccess from "./salesuccess";
const host = import.meta.env.VITE_REACT_APP_HOST;

export default function Order() {
  const [sAddress, setsAddress] = useState();
  const [AddressLoading, setAddressLoading] = useState(false);
  const [AddressDisabled, setAddressDisabled] = useState(true);
  const { swrdata, isLoading } = useSKU();
  const [dearsku, setDearData] = useState();
  const [customerlist, setCustomerList] = useState();
  const [changevals, setValues] = useState();
  const [continuesubmit, setContinueSubmit] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [successopen, setSuccessOpen] = useState(false);
  const [secondsubmit, setSecondSubmit] = useState(false);
  const [addchargedisplay, setACDisplay] = useState(false);
  const [serviceskus, setDearServiceSkus] = useState();
  const [resdata, setResData] = useState();
  const [shipnotearr, setShippingNoteArray] = useState([]);
  const toast = useToast();
  const { state } = useLocation();
  const {
    isOpen: alertisopen,
    onOpen: alertonOpen,
    onClose: alertonClose,
  } = useDisclosure();
  const okRef = React.useRef();
  console.log(state);
  const styles = {
    control: (css) => ({
      ...css,
      width: 150,
    }),
    menu: ({ width, ...css }) => ({
      ...css,
      width: "max-content",
      minWidth: "20%",
    }),
    // Add padding to account for width of Indicators Container plus padding
    option: (css) => ({ ...css, width: 500 }),
  };

  const checkSkus = async (values) => {
    console.log(values);
    await fetch(host + "/api/checksku", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }).then(async (res) => {
      if (res.status === 200) {
        const data = await res.json();
        const submitvals = { ...values, ...data };
        console.log(submitvals);
        setValues(submitvals);
        setContinueSubmit(true);
        onOpen();
        return submitvals;
      } else if (res.status === 400) {
        return res.json().then((response) => {
          toast({
            title: "Out of Stock",
            message: response,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          throw new Error(response.error);
        });
      }
      await res.json().then((response) => {
        onClose();
        toast({
          title: "Failed!",
          description: "Order Failed, Check for Errors and Retry",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        throw new Error(response);
      });
    });
  };

  const orderSubmit = async (basearray) => {
    const submitvals = changevals;
    if (state) {
      submitvals.saleID = state.ID;
      submitvals.saleMemo = state.Order.Memo;
    }
    submitvals.basearray = basearray;
    //Disallow Refund item in addcharge array
    if (submitvals.addcharge) {
      const refund = submitvals.addcharge.find(
        (charge) => charge.item === "Refund"
      );
      if (refund) {
        toast({
          title: "Refund is deprecated",
          description: "Create a credit memo instead for this customer",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setSecondSubmit(false);
        return;
      }
      //Check if discounts exceed the total price
      const totalprice = basearray.reduce(
        (acc, curr) => acc + curr.Price * curr.qty,
        0
      );
      const discount = submitvals.addcharge.reduce(
        (acc, curr) => acc + curr.price,
        0
      );
      if (discount > totalprice) {
        toast({
          title: "Error: Discount Exceeds Total Price",
          description: "Discounts cannot exceed the total price of the order",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setSecondSubmit(false);
        return;
      }
    }
    await fetch(host + "/api/orderdata", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submitvals),
    })
      .then(async (res) => {
        if (res.status === 200) {
          setSecondSubmit(false);
          reset();
          const resdata = await res.json();
          console.log(resdata);
          setResData(resdata);
          setSuccessOpen(true);
          onClose();
        } else {
          if (res.status === 409) {
            const resdata = await res.json();
            toast({
              title: "Error: Order has been rolled back",
              description: resdata,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else if (res.status === 400) {
            toast({
              title: "Error: Order Failed",
              description: res.statusText,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            throw new Error(res.status);
          }
        }
        setContinueSubmit(false);
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Failed!",
          description: "Order Failed, Check for Errors and Retry",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const columns = [
    {
      Header: "Original SKU",
      accessor: "originalsku",
    },
    {
      Header: "New SKU",
      accessor: "newsku",
    },
  ];

  const ordercolumns = [
    {
      Header: "SKU",
      accessor: "SKU",
    },
    {
      Header: "Product Name",
      accessor: "Name",
    },
    {
      Header: "Price",
      accessor: "Price",
    },
    {
      Header: "Quantity",
      accessor: "qty",
    },
  ];

  const addchargecolumns = [
    {
      Header: "Item",
      accessor: "item",
    },
    {
      Header: "Price",
      accessor: "price",
    },
  ];
  const schema = Yup.object({
    customer: Yup.string()
      .required("Required")
      .transform((curr, orig) => orig.value),
    date: Yup.date()
      .required("Required")
      .min(dayjs().startOf("day"), "Date cannot be in the past"),
    shippingnotes: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .trim()
      .max(191, "Must be less than 191 Characters"),
    shippingaddress: Yup.string()
      .required("Required")
      .transform((curr, orig) => orig.value),
    addchargedisplay: Yup.boolean(),
    products: Yup.array(
      Yup.object({
        sku: Yup.string()
          .required("Required")
          .transform((curr, orig) => orig.value),
        qty: Yup.number().required("Required"),
        customprice: Yup.number()
          .nullable()
          .transform((curr, orig) => (orig === "" ? null : curr))
          .positive("Must be a positive value"),
      }).nullable()
    ),
    addcharge: Yup.array(
      Yup.object({
        item: Yup.string()
          .transform((curr, orig) => orig.value)
          .when("addchargedisplay", {
            is: true,
            then: Yup.string().required("Required"),
          }),
        price: Yup.number().when("addchargedisplay", {
          is: true,
          then: Yup.number().required("Required"),
        }),
      }).nullable()
    ),
  });
  const dateformat = "MM/DD";
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, isSubmitting },
    reset,
    control,
  } = useForm({
    defaultValues: {
      date: dayjs().add(15, "hour").format("YYYY-MM-DD"),
      products: [{ sku: "", qty: "1", customprice: "" }],
      addchargedisplay: false,
    },
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "products", // unique name for your Field Array
  });

  const {
    fields: acfields,
    append: acappend,
    remove: acremove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "addcharge", // unique name for your Field Array
  });

  useEffect(() => {
    if (!isLoading) {
      const sku = swrdata.sku.map((a) => ({
        value: a.SKU,
        label: a.SKU + ":" + a.Name,
      }));
      setDearData(sku);
      const customername = swrdata.customers.map((o) => ({
        value: o.Name,
        label: o.Name,
      }));
      setCustomerList(customername);
      const service = swrdata.servicesku.map((a) => ({
        value: a.Name,
        label: a.Name,
      }));
      setDearServiceSkus(service);
      if (state) {
        const values = {
          customer: {
            value: state.Customer,
            label: state.Customer,
          },
          date: dayjs(state.SaleOrderDate).format("YYYY-MM-DD"),
          products: state.products,
        };
        if (state.addchargedisplay) {
          values.addchargedisplay = true;
          values.addcharge = state.addcharge;
        }
        const customerdata = swrdata.customers.find(
          (swrcustomer) => swrcustomer.Name === state.Customer
        );
        if (customerdata.CreditHold) {
          alertonOpen();
        }
        console.log(values);
        const darray = customerdata.dAddress;
        let displaybody = [];
        customerdata.pAddress && darray.push(customerdata.pAddress);
        for (let i = 0; i < darray.length; i++) {
          const body = {
            label: darray[i].Line1,
            value: JSON.stringify(darray[i]),
          };
          displaybody.push(body);
        }
        if (
          customerdata.ShippingNoteArray &&
          customerdata.ShippingNoteArray.find(
            (note) => note.ID === displaybody[0].ID
          )
        ) {
          var addshippingnote =
            customerdata.ShippingNoteArray.find(
              (note) => note.ID === displaybody[0].ID
            ).dinstruct ?? customerdata.ShippingNote;
        } else {
          var addshippingnote = customerdata.ShippingNote;
        }
        setValue("shippingnotes", addshippingnote, {
          shouldDirty: true,
          shouldTouch: true,
        });
        setValue("shippingaddress", displaybody[0], {
          shouldDirty: true,
          shouldTouch: true,
        });
        setShippingNoteArray(customerdata.ShippingNoteArray);
        setsAddress(displaybody);
        setAddressLoading(false);
        setAddressDisabled(false);
        setValue("customer", values.customer, {
          shouldDirty: true,
          shouldTouch: true,
        });
        reset(values, { keepDirtyValues: true });
      }
    }
  }, [swrdata, isLoading]);
  console.log("Errors:");
  console.log(errors);
  console.log("Change Vals");
  console.log(changevals);
  return (
    <Suspense fallback={<h2>Loading...</h2>}>
      {isLoading ? (
        <Flex grow={1} align="center" justify="center">
          <Spinner />
          <p>Loading...</p>
        </Flex>
      ) : (
        <Card>
          <CardHeader>
            <Heading textAlign="center">
              {state ? "Edit Order" : "New Internal Order"}
            </Heading>
          </CardHeader>
          <CardBody>
            <Flex grow={0.5} align="end" justify="center">
              <Stack grow={0.5} direciton="vertical" w="100%">
                {successopen && <SaleSuccess data={resdata} />}
                <form id="orderform" onSubmit={handleSubmit(checkSkus)}>
                  <Stack>
                    <Box>
                      <Modal
                        isOpen={isOpen}
                        size="2xl"
                        onClose={() => {
                          onClose();
                          setSecondSubmit(false);
                          setContinueSubmit(false);
                        }}
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Order Confirmation</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            {secondsubmit ? (
                              <Submitting />
                            ) : (
                              <>
                                {changevals?.changes.length > 0 && (
                                  <>
                                    <Box position="relative" padding="10">
                                      <Divider />
                                      <AbsoluteCenter bg="white" px="4">
                                        <Heading size="md" mt={2}>
                                          Changes
                                        </Heading>
                                      </AbsoluteCenter>
                                    </Box>
                                    <Table
                                      columns={columns}
                                      data={changevals?.changes}
                                    />
                                    <Heading size="xs" mt={2}>
                                      Changed Lines:
                                    </Heading>
                                    <Table
                                      columns={ordercolumns}
                                      data={changevals?.changed}
                                    />
                                    <Heading size="xs" mt={2}>
                                      Original Lines:
                                    </Heading>
                                    <Table
                                      columns={ordercolumns}
                                      data={changevals?.original}
                                    />
                                  </>
                                )}
                                <Box position="relative" padding="10">
                                  <Divider />
                                  <AbsoluteCenter bg="white" px="4">
                                    <Heading size="md" mt={2}>
                                      Submitted Order
                                    </Heading>
                                  </AbsoluteCenter>
                                </Box>
                                {changevals?.changes.length > 0 && (
                                  <Text>
                                    The below table does not include the changed
                                    lines shown above
                                  </Text>
                                )}
                                {changevals?.basearray.length > 0 && (
                                  <Table
                                    columns={ordercolumns}
                                    data={changevals?.basearray}
                                  />
                                )}
                                {changevals?.basearray.length > 0 && (
                                  <Divider />
                                )}
                                {changevals?.addcharge && (
                                  <>
                                    {" "}
                                    <Heading size="sm" mt={2}>
                                      Additional Charges
                                    </Heading>
                                    <Table
                                      columns={addchargecolumns}
                                      data={changevals?.addcharge}
                                    />{" "}
                                  </>
                                )}
                              </>
                            )}
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              isLoading={secondsubmit}
                              colorScheme="red"
                              mr={1}
                              onClick={() => {
                                onClose();
                                setContinueSubmit(false);
                              }}
                            >
                              Cancel
                            </Button>
                            {changevals?.changes.length > 0 && (
                              <>
                                <Button
                                  isLoading={secondsubmit}
                                  colorScheme="blue"
                                  mr={1}
                                  onClick={() => {
                                    setSecondSubmit(true);
                                    orderSubmit(
                                      changevals.basearray.concat(
                                        changevals.original
                                      )
                                    );
                                  }}
                                >
                                  Keep Original Order
                                </Button>
                                <Button
                                  isLoading={secondsubmit}
                                  colorScheme="green"
                                  mr={1}
                                  onClick={() => {
                                    setSecondSubmit(true);
                                    orderSubmit(
                                      changevals.basearray.concat(
                                        changevals.changed
                                      )
                                    );
                                  }}
                                >
                                  Accept Changes
                                </Button>
                              </>
                            )}
                            {changevals?.changes.length === 0 && (
                              <Button
                                isLoading={secondsubmit}
                                colorScheme="green"
                                mr={1}
                                onClick={() => {
                                  setSecondSubmit(true);
                                  orderSubmit(changevals.basearray);
                                }}
                              >
                                Confirm
                              </Button>
                            )}
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                      <AlertDialog
                        isOpen={alertisopen}
                        leastDestructiveRef={okRef}
                        onClose={alertonClose}
                      >
                        <AlertDialogOverlay>
                          <AlertDialogContent>
                            <AlertDialogHeader>Credit Hold</AlertDialogHeader>
                            <AlertDialogBody>
                              This Customer is on Credit Hold. Contact
                              ar@vibecartons.com to resolve.
                            </AlertDialogBody>
                            <AlertDialogFooter>
                              <Button ref={okRef} onClick={alertonClose}>
                                Ok
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                      </AlertDialog>
                      <Checkbox
                        {...register("addchargedisplay")}
                        display="none"
                        isChecked={addchargedisplay}
                      />
                      <FormControl
                        isRequired
                        isInvalid={!!errors.customer && dirtyFields.customer}
                        isDisabled={isSubmitting || continuesubmit}
                      >
                        <FormLabel>Customer Name:</FormLabel>
                        <Controller
                          control={control}
                          name="customer"
                          render={({ field }) => (
                            <Select
                              name={field.name}
                              value={field.value}
                              useBasicStyles
                              placeholder="Required"
                              isSearchable
                              options={customerlist}
                              autoFocus
                              onChange={async (customer, e) => {
                                setAddressLoading(true);
                                field.onChange(customer);
                                console.log(customer);
                                const customerdata = swrdata.customers.find(
                                  (swrcustomer) =>
                                    swrcustomer.Name === customer.value
                                );
                                console.log(customerdata);
                                if (customerdata.CreditHold) {
                                  alertonOpen();
                                }
                                const darray = customerdata.dAddress;
                                let displaybody = [];
                                customerdata.pAddress &&
                                  darray.push(customerdata.pAddress);
                                for (let i = 0; i < darray.length; i++) {
                                  const body = {
                                    label: darray[i].Line1,
                                    value: JSON.stringify(darray[i]),
                                  };
                                  displaybody.push(body);
                                }
                                if (
                                  customerdata.ShippingNoteArray &&
                                  customerdata.ShippingNoteArray.find(
                                    (note) => note.ID === displaybody[0].ID
                                  )
                                ) {
                                  var addshippingnote =
                                    customerdata.ShippingNoteArray.find(
                                      (note) => note.ID === displaybody[0].ID
                                    ).dinstruct ?? customerdata.ShippingNote;
                                } else {
                                  var addshippingnote =
                                    customerdata.ShippingNote;
                                }
                                setValue("shippingnotes", addshippingnote, {
                                  shouldDirty: true,
                                  shouldTouch: true,
                                });
                                setValue("shippingaddress", displaybody[0], {
                                  shouldDirty: true,
                                  shouldTouch: true,
                                });
                                setShippingNoteArray(
                                  customerdata.ShippingNoteArray
                                );
                                setsAddress(displaybody);
                                setAddressLoading(false);
                                setAddressDisabled(false);
                              }}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {errors?.customer?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl
                        isDisabled={isSubmitting || continuesubmit}
                        isInvalid={!!errors.shippingaddress}
                      >
                        <FormLabel>Shipping Address:</FormLabel>
                        <Controller
                          control={control}
                          name="shippingaddress"
                          render={({ field }) => (
                            <Select
                              name={field.name}
                              useBasicStyles
                              options={sAddress}
                              isLoading={AddressLoading}
                              isDisabled={AddressDisabled}
                              value={field.value}
                              onChange={(value) => {
                                setValue(
                                  "shippingnotes",
                                  shipnotearr?.find(
                                    (note) => note.ID === value.ID
                                  )?.dinstruct ?? ""
                                );
                                field.onChange(value);
                              }}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {errors?.shippingaddress?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box>
                      <Wrap>
                        <FormControl
                          isRequired
                          isInvalid={!!errors.date}
                          isDisabled={isSubmitting || continuesubmit}
                        >
                          <FormLabel>Required by Date:</FormLabel>
                          <Input
                            name="date"
                            type="date"
                            format={dateformat}
                            {...register("date")}
                          />
                          <FormErrorMessage>
                            {errors?.date?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Wrap>
                    </Box>
                    <Box>
                      <FormControl
                        isInvalid={
                          !!errors?.shippingnotes && dirtyFields?.shippingnotes
                        }
                        isDisabled={isSubmitting || continuesubmit}
                      >
                        <FormLabel>Delivery Instructions:</FormLabel>
                        <Textarea
                          size="sm"
                          name="shippingnotes"
                          {...register("shippingnotes")}
                        />
                        <FormErrorMessage>
                          {errors?.shippingnotes?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Heading as="h3" size="sm">
                      Order Data:
                    </Heading>
                    <Text>
                      Input SKU and Quantity for this order. Click Add Row to
                      input new SKU.
                    </Text>
                    <Text as="i" size="xs" display={{ md: "none" }}>
                      Custom Price input is not shown on small screens.
                    </Text>
                    {fields.map((product, index) => (
                      <Wrap key={product.id} justify="center" align="center">
                        <WrapItem w={[, 250, , 550]}>
                          <FormControl
                            isInvalid={!!errors?.products?.[index]?.sku}
                            isDisabled={isSubmitting || continuesubmit}
                          >
                            <FormLabel>SKU:</FormLabel>
                            <Controller
                              control={control}
                              name={`products.${index}.sku`}
                              render={({ field }) => (
                                <Select
                                  value={field.value}
                                  styles={styles}
                                  name={field.name}
                                  options={dearsku}
                                  isSearchable
                                  onChange={field.onChange}
                                />
                              )}
                            />
                            <FormErrorMessage>
                              {errors?.products?.[index]?.sku?.message}
                            </FormErrorMessage>
                          </FormControl>
                        </WrapItem>
                        <WrapItem w={[75, , , , 150]}>
                          <FormControl
                            isDisabled={isSubmitting || continuesubmit}
                          >
                            <FormLabel>Qty</FormLabel>
                            <NumberInput pl={2} isRequired>
                              <NumberInputField
                                {...register(`products.${index}.qty`)}
                              />
                            </NumberInput>
                          </FormControl>
                          <FormErrorMessage>
                            {errors?.products?.[index]?.qty?.message}
                          </FormErrorMessage>
                        </WrapItem>
                        <WrapItem hideBelow="md" w={140}>
                          <FormControl
                            isDisabled={isSubmitting || continuesubmit}
                          >
                            <FormLabel>Custom Price</FormLabel>
                            <NumberInput pl={2}>
                              <NumberInputField
                                {...register(`products.${index}.customprice`)}
                              />
                            </NumberInput>
                          </FormControl>
                          <FormErrorMessage>
                            {errors?.products?.[index]?.customprice?.message}
                          </FormErrorMessage>
                        </WrapItem>
                        <WrapItem direction="row" align="end" pt={8}>
                          <Button
                            ml={2}
                            hideBelow="md"
                            colorScheme="red"
                            variant="solid"
                            size="md"
                            onClick={() => remove(index)}
                          >
                            Delete Row
                          </Button>
                        </WrapItem>
                        <WrapItem direction="row" align="end" pt={8}>
                          <Button
                            ml={2}
                            hideFrom="md"
                            colorScheme="red"
                            variant="solid"
                            size="md"
                            onClick={() => remove(index)}
                          >
                            X
                          </Button>
                        </WrapItem>
                      </Wrap>
                    ))}
                    <Button
                      size="sm"
                      colorScheme="green"
                      onClick={() =>
                        append({ sku: "", qty: "1", customprice: "" })
                      }
                    >
                      Add Row
                    </Button>
                    <Divider />
                    <Button
                      type="button"
                      colorScheme="blue"
                      onClick={() => {
                        setACDisplay(!addchargedisplay);
                        addchargedisplay
                          ? acremove()
                          : acappend({ item: "", price: "" });
                      }}
                    >
                      {addchargedisplay
                        ? "Remove Additional Charges"
                        : "Add Additional Charges"}
                    </Button>
                    <Flex display={addchargedisplay ? "flex" : "none"}>
                      {acfields.map((acharge, index) => (
                        <Wrap key={acharge.id} justify="center" align="center">
                          <WrapItem w={[, 250, , 550]}>
                            <FormControl
                              isInvalid={
                                !!errors?.addcharge?.[index]?.item &&
                                dirtyFields?.addcharge?.[index]?.item
                              }
                              isDisabled={isSubmitting || continuesubmit}
                            >
                              <FormLabel>Item:</FormLabel>
                              <Controller
                                control={control}
                                name={`addcharge.${index}.item`}
                                render={({ field }) => (
                                  <Select
                                    value={field.value}
                                    styles={styles}
                                    name={field.name}
                                    options={serviceskus}
                                    isSearchable
                                    onChange={field.onChange}
                                  />
                                )}
                              />
                              <FormErrorMessage>
                                {errors?.addcharge?.[index]?.item?.message}
                              </FormErrorMessage>
                            </FormControl>
                          </WrapItem>
                          <WrapItem w={[75, , , , 150]}>
                            <FormControl
                              isDisabled={isSubmitting || continuesubmit}
                              isInvalid={
                                !!errors?.addcharge?.[index]?.price &&
                                dirtyFields?.addcharge?.[index]?.price
                              }
                            >
                              <FormLabel>Price</FormLabel>
                              <Input
                                pattern="(-)?[0-9]*(.[0-9]+)?"
                                pl={2}
                                {...register(`addcharge.${index}.price`)}
                              />
                            </FormControl>
                            <FormErrorMessage>
                              {errors?.addcharge?.[index]?.price?.message}
                            </FormErrorMessage>
                          </WrapItem>
                          <WrapItem direction="row" align="end" pt={8}>
                            <Button
                              ml={2}
                              display={{ base: "none", md: "flex" }}
                              colorScheme="red"
                              variant="solid"
                              size="md"
                              onClick={() => acremove(index)}
                            >
                              Delete Row
                            </Button>
                          </WrapItem>
                          <WrapItem direction="row" align="end" pt={8}>
                            <Button
                              ml={2}
                              display={{ base: "flex", md: "none" }}
                              colorScheme="red"
                              variant="solid"
                              size="md"
                              onClick={() => acremove(index)}
                            >
                              X
                            </Button>
                          </WrapItem>
                        </Wrap>
                      ))}
                      <Button
                        size="sm"
                        colorScheme="green"
                        onClick={() => acappend({ item: "", price: "" })}
                      >
                        Add Row
                      </Button>
                    </Flex>
                  </Stack>
                </form>
              </Stack>
            </Flex>
          </CardBody>
          <CardFooter justify="center">
            <Button
              isLoading={isSubmitting || continuesubmit}
              loadingText="Submitting"
              colorScheme="blue"
              form="orderform"
              variant="solid"
              type="submit"
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      )}
    </Suspense>
  );
}
