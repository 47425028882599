import { useState, useEffect } from 'react';
import {
    useDisclosure,
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Divider,
    useToast,
} from '@chakra-ui/react';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Select } from 'chakra-react-select';
import useSKU from './components/fetchsku.js';

const host = import.meta.env.VITE_REACT_APP_HOST;


export default function CustomerReq() {
    const {
        handleSubmit,
        register,
        control,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues:{
            customer:"",
            skuarray:[{"sku":""}],
        }
    })
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { swrdata, isLoading } = useSKU();
    const [loadOptions, setCustomers] = useState();
    const [skuoptions, setSKU] = useState();
    const [submitting, setSubmitting] = useState(false);
    const { fields, append, remove} = useFieldArray({
        control,
        name: "skuarray",
    });
    const toast = useToast();

    useEffect(() => {
        setCustomers(swrdata.customers.map(o => ({ value: o.CustomerID, label: o.Name })));
        setSKU(swrdata.sku.map(a=>({value:a.SKU,label:a.SKU + ":" + a.Name})));
    }, [swrdata, isLoading])

    const onSubmit = async (data) => {
        await fetch(host + "/api/addsticky", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.ok) {
                setSubmitting(false);
                toast({
                    title: "Success",
                    description: "Changes Submitted",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                setSubmitting(false);
                toast({
                    title: "Error",
                    description: "Error submitting changes",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
        })
    }


    return (
        <>
            <Button isLoading={isLoading} m={2} onClick={() => onOpen()}>Customer Required Items</Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create SKU requirements for Customer</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>This form creates "locks" for certain SKU for a customer. This will prevent the system from assigning interchangeable SKUs for that customer.</Text>
                        <form id="customerreq" onSubmit={handleSubmit(onSubmit)}>
                            <FormControl isInvalid={errors.customer}>
                                <FormLabel htmlFor='customer'>Select Customer:</FormLabel>
                                <Controller
                                    name="customer"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                {...field}
                                                isClearable
                                                value={field.value}
                                                options={loadOptions}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                            />)
                                    }}
                                />
                            </FormControl>
                            <Divider />
                                <FormControl isInvalid={errors.skuarray}>
                                    <FormLabel htmlFor='skuarray'>SKU:</FormLabel>
                                    {fields.map((item, index) => (
                                         <Flex direction="row" m={2} key={item.id} justify="space-between" >
                                            <Controller
                                                name={`skuarray.${index}.sku`}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            chakraStyles={{
                                                                container:(provided) => ({
                                                                    ...provided,
                                                                    width:"100%",
                                                                    mr:2
                                                                })
                                                            }}                                                                                                                
                                                            {...field}
                                                            isClearable
                                                            value={field.value}
                                                            options={skuoptions}
                                                            onChange={field.onChange}
                                                            onBlur={field.onBlur}
                                                        />)
                                                }}
                                            />
                                            <Button onClick={() => remove(index)}>Delete</Button>
                                        </Flex>
                                    ))}
                                </FormControl>
                                <Button mt={2} ml={2} onClick={() => append({sku: ""})}>Add SKU</Button>
                                           
                        </form> 
                    </ModalBody>
                    <ModalFooter>
                    <Button form="customerreq" isLoading={submitting} type="submit" color="blue">Submit</Button>    
                    </ModalFooter>
                </ModalContent>

            </Modal>
        </>
    )
}