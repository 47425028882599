import {
    VStack,
    Text,
    CircularProgress
} from '@chakra-ui/react';
export default function Loading(){
return(
<VStack>
<Text>Loading</Text>
<CircularProgress color="purple" isIndeterminate />
</VStack>
)}

export function Submitting(){
    return(
    <VStack>
    <Text>Submitting</Text>
    <CircularProgress color="purple" isIndeterminate />
    </VStack>
    )}